export interface AccountListProps {
  text: string;
  value: string;
}

export const showAccountDetail = ['3', '4', '7'];
export const Leaves = ['chequeLeavesCount1', 'chequeLeavesCount2'];
export const closedStatus = 'CLOSED';
export const addressProof = [
  {
    value: 'aadhar',
    text: 'Aadhar Card',
  },
  {
    value: 'pan',
    text: 'PAN Card',
  },
  {
    value: 'license',
    text: 'License',
  },
  {
    value: 'electricity_bill',
    text: 'Electricity Bill',
  },
  {
    value: 'passport',
    text: 'Passport',
  },
];

export const customerAccountStatus = [{ status: '6' }, { status: '8' }];

export const dateRestriction = {
  DAY: 'days',
  YEARS: 'years',
  MONTHS: 'months',
  PSTV_PAY_PAST: 90,
  PSTV_PAY_FUTURE: 5,
  SERVICE_REQ_PAST: 90,
  SERVICE_REQ_FUTURE: 1,
};

export const charLimit = {
  MAX_PAYEE_NAME: 100,
  MIN_PAYEE_NAME: 3,
  MAX_CHEQUE_NO: 6,
  MIN_CHEQUE_NO: 1,
  MAX_REQ_NO: 26,
  MIN_REQ_NO: 3,
  MIN_TNSFR_AMT: 1,
  MAX_TNSFR_AMT: 12,
};

export const aggregateNumberDropdown = [
  { text: '1', value: '1' },
  { text: '2', value: '2' },
  { text: '3', value: '3' },
  { text: '4', value: '4' },
  { text: '5', value: '5' },
  { text: '6', value: '6' },
  { text: '7', value: '7' },
  { text: '8', value: '8' },
  { text: '9', value: '9' },
  { text: '10', value: '10' },
];

export const chequeStatusTypes = {
  U: 'unpaid',
  S: 'stopped',
  P: 'paid',
  R: 'return',
};

export const payloadNearbyBranch = {
  filterType: 'BANK_NAME',
  filterValue: 'AU SMALL FINANCE BANK LIMITED',
  bankName: 'AU SMALL FINANCE BANK LIMITED',
};

export const ChequeAddressType = ['C', 'B'];

export const channel = 'WEB_APP';

export enum errorTypeOption {
  INFO = 'info',
  SUCCESS = 'success',
  ERROR = 'error',
  LOADING = 'loading',
  VERIFIED = 'verified',
  BLANK = '',
}
export enum chequeStatus {
  North,
  East,
  South,
  West,
}

export const mockRequestType = [
  {
    id: '1',
    serviceRequestName: 'New Cheque Book',
  },
  {
    id: '2',
    serviceRequestName: 'Stop Cheque',
  },
  {
    id: '3',
    serviceRequestName: 'Communication Address',
  },
  {
    id: '4',
    serviceRequestName: 'Form 15 G/H',
  },
  {
    id: '5',
    serviceRequestName: 'Positive Pay',
  },
  {
    id: '6',
    serviceRequestName: 'Update Nominee',
  },
  {
    id: '7',
    serviceRequestName: 'Update Email ID',
  },
  {
    id: '8',
    serviceRequestName: 'Update Mobile Number',
  },
  {
    id: '9',
    serviceRequestName: 'Update NickName',
  },
];

export const country = 'India';

export const custTypeIndividual = 'INDIVIDUAL_CUSTOMER';
export const custTypeCorporate = 'CORPORATE_CUSTOMER';
export const pinMaxLength = 6;

export const CROSS_SELL_NAME = {
  VIDEO_BANKING: 'VB_WELCOME_PAGE',
  MOBILE_BANKING: 'VB_UPDATE_MOBILE_NO_PAGE',
  WORKFLOW_GROUP_ID: 'NTB_PRELOGIN_VB',
};

export const FE_SERVICE_REQUEST_CONFIG = {
  CONFIG_TYPE: 'FE_SERVICE_REQUEST_CONFIG',
  MODULE: 'feServiceRequestConfig',
};

export const UNABLE_STOP_CHEQUE_SERIES = [
  'CSR3013',
  'CSR3011',
  'CSR3845',
  'CSR3230',
];

export const SAVING_ACC_INTEREST_CERT = ['6', '8'];
export const ACC_CERTIFICATE_TYPE = 'SAVINGS';
export const FINANCIAL_YEAR = {
  C: 'C',
  P: 'P',
};

export const FE_CSR_CONFIG = {
  CONFIG_TYPE: 'CSR_CONFIG',
  MODULE: 'csrConfig',
};

export const accountToProductGet = ['Overdraft'];
