import { AUEndPoints, Service } from '@core-services';
import { handleError } from '@core-utils';
import { ReqBillerTxnDetails } from '@modules/bill-payments/models/bill-payments.model';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const billerDetails = createAsyncThunk(
  'bbps/biller-details',
  async (queryParam: string, { rejectWithValue }) => {
    const apiPath = AUEndPoints.bbpsService.BILLER_DETAILS;
    try {
      const response = await Service.send({
        method: 'POST',
        baseurl: '',
        url: `${apiPath}?${queryParam ?? ''}`,
      });
      if (
        response.data === null ||
        response.data.status === 'error' ||
        response.data.data === null
      ) {
        return rejectWithValue(handleError(response.data));
      }

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.getErrorMessage());
    }
  }
);

export const getBillerTxnDetails = createAsyncThunk(
  'BillerTxnDetails',
  async (reqObj: ReqBillerTxnDetails, { rejectWithValue }) => {
    try {
      const response = await Service.send({
        method: 'POST',
        baseurl: '',
        url: AUEndPoints.bbpsService.BILLER_TXN_DETAILS,
        obj: reqObj,
      });
      if (
        response.data === null ||
        response.data.status === 'error' ||
        response.data.data === null
      ) {
        return rejectWithValue(handleError(response.data));
      }
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.getErrorMessage());
    }
  }
);

export const getBbpsIcons = createAsyncThunk(
  'api/getBppsIcons',
  async (_req, { rejectWithValue }) => {
    try {
      const response = await Service.send({
        method: 'GET',
        baseurl: AUEndPoints.strapiService.getBbpsIcons,
        url: '',
      });
      if (
        response.data === null ||
        response.data.status === 'error' ||
        response.data.data === null
      ) {
        return rejectWithValue(handleError(response.data));
      }
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.getErrorMessage());
    }
  }
);
