import { useTranslation } from 'react-i18next';

import { AU_BackButton } from '@core-components/molecules';
import { BackButtonProps } from '@core-stores';

interface ScreenLayoutProps {
  welcomeHeading?: string;
  pageHeading?: string;
  headingLink?: React.JSX.Element;
  leftContainer: React.JSX.Element | undefined;
  isShowBackBtn: boolean;
  rightContainer?: React.JSX.Element | boolean;
  backBtnProps?: BackButtonProps;
  rightContainerClasses?: string;
  leftContainerClasses?: string;
  rightHeadingLogo?: React.JSX.Element;
}

export const AU_ScreenLayout = ({
  welcomeHeading,
  pageHeading,
  headingLink,
  leftContainer,
  leftContainerClasses = 'bg-white',
  isShowBackBtn = true,
  backBtnProps,
  rightContainer = <div></div>,
  rightContainerClasses,
  rightHeadingLogo,
}: ScreenLayoutProps) => {
  const { t } = useTranslation();
  const welComeClass = welcomeHeading ? 'flex-col' : 'items-center';
  return (
    <div className="screen-layout-container w-full py-6 px-4 bg-gray-100">
      <div className="flex w-full md:justify-between max-md:flex-col md:pr-[19rem]">
        <div className="flex flex-row justify-between w-full">
          <div className={`flex gap-x-2 md:justify-center ${welComeClass}`}>
            {isShowBackBtn && (
              <div>
                <AU_BackButton {...backBtnProps} />
              </div>
            )}
            {welcomeHeading && (
              <span
                className="m-text-lg-regular text-gray-700"
                data-testid="WCME-HEAD"
              >
                {t(welcomeHeading)}
              </span>
            )}
            {pageHeading && (
              <h1
                className="m-display-xl-medium text-gray-800 pb-4"
                data-testid="HEAD-CARD"
              >
                {t(pageHeading)}
              </h1>
            )}
          </div>
          {rightHeadingLogo && (
            <div className="self-start">{rightHeadingLogo}</div>
          )}
        </div>

        {headingLink && (
          <p className="heading-link my-auto pb-4">{headingLink}</p>
        )}
      </div>
      <div className="flex w-full max-md:flex-col">
        <div
          className={`w-full left-side-container rounded-xl max-md:mb-4 ${
            leftContainerClasses ?? ''
          }`}
        >
          {leftContainer}
        </div>
        {rightContainer && (
          <div
            className={`md:w-72 lg:min-w-[18rem] md:ml-4 max-md:w-full max-md:flex rounded-xl ${
              rightContainerClasses ?? ''
            }`}
          >
            {rightContainer}
          </div>
        )}
      </div>
    </div>
  );
};
