import React from 'react';

import { endOfDay, startOfDay } from 'date-fns';

export const countryCode = '+91';
export const showManageYourBusinessSection = false;

export const getIndvidualVpaDetail = [
  {
    receivedToday: 0,
    numberOfPayment: 0,
  },
];

export const bottomTab = [
  { icon: 'profile-2user-outline', label: 'user' },
  { icon: 'settings', label: 'setting' },
  { icon: 'graph', label: 'report' },
];

export interface QrHeaderPropsType {
  setCurrentVpaDetail: (item) => void;
  currentVpaDetail: any;
}
export interface ShowQrModalPropsType {
  setCurrentVpaDetail: (item) => void;
  setShowGenerateQrModal: (item: string) => void;
  currentVpaDetail: any;
  setSelectedAmount: any;
  setSelectedVpaDropdown: any;
  setRemarks?: any;
}
export interface RecentTransactionPropsType {
  currentVpaDetail: any;
}

export const ntbQRFeatures = [
  { id: 1, title: 'applyQrApplicationDesc1', icon: 'money-remove' },
  { id: 2, title: 'applyQrApplicationDesc2', icon: 'wallet-add' },
  {
    id: 3,
    title: 'applyQrApplicationDesc3',
    icon: 'service',
  },
];

// Intentional - for future use of Users, Reports and Invoice
export const ntbQRStoreModules = [
  {
    id: 1,
    title: 'users',
    icon: 'https://s3.ap-south-1.amazonaws.com/039912797956-dev-strapi-images.aubankuat.in/ntb_store_users_ed05bf2d8b.svg?updated_at=2023-11-07T12:03:55.403Z',
    description: '',
    isDisabled: false,
    isVisible: true,
  },
  {
    id: 2,
    title: 'reports',
    icon: 'https://s3.ap-south-1.amazonaws.com/039912797956-dev-strapi-images.aubankuat.in/ntb_store_receipt_9744d31313.svg?updated_at=2023-11-07T12:04:04.866Z',
    description: '',
    isDisabled: false,
    isVisible: true,
  },
  {
    id: 3,
    title: 'invoice',
    icon: 'https://s3.ap-south-1.amazonaws.com/039912797956-dev-strapi-images.aubankuat.in/ntb_store_invoice_7775d00dd5.svg?updated_at=2023-11-07T12:04:13.709Z',
    description: '',
    isDisabled: false,
    isVisible: true,
  },
];

export interface IQrApplication {
  title: string;
  percent: number;
}

export interface IPopUpData {
  title: string;
  subtitle?: string;
  navigateToApplication: boolean;
  img?: React.JSX.Element;
  btn1: string;
  btn2?: string;
  body: boolean;
  btn1Id: string;
  btn2Id?: string;
}

export const ntbStorePopupData = {
  noData: {
    title: 'noQrWithUs',
    btn1: 'applyQR',
    navigateToApplication: true,
    btn2: 'cancel',
    btn1Id: 'APLY-QR',
    btn2Id: 'CNCL',
    body: true,
  },
  resumeApplication: {
    title: 'oopsCaps',
    subtitle: 'completeQrApplicationToEnable',
    btn1: 'resumeApplication',
    navigateToApplication: true,
    btn2: 'close',
    btn1Id: 'RESM-APPL',
    btn2Id: 'CLSE',
    body: false,
  },
  underReview: {
    title: 'oopsCaps',
    subtitle: 'qrJourneyUnderReview',
    navigateToApplication: false,
    btn1: 'okay',
    btn1Id: 'OKAY',
    body: false,
  },

  videoKycPending: {
    title: 'videoKycPending',
    subtitle: 'completeQrApplication',
    navigateToApplication: true,
    btn1: 'resumeVideoKyc',
    btn2: 'close',
    btn1Id: 'RESM-VDEO-KYC',
    btn2Id: 'CLSE',
    body: false,
  },
  detailsRequired: {
    title: 'moreDetailsRequired',
    subtitle: 'provideDetailsForQR',
    navigateToApplication: true,
    btn1: 'fillDetails',
    btn2: 'close',
    btn1Id: 'FILL-DTLS',
    btn2Id: 'CLSE',
    body: false,
  },
};

export const VKYCSTATUS = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  SUCCESS: 'SUCCESS',
  CLOSED: 'CLOSED',
  SCHEDULED: 'SCHEDULED',
  ALL_AGENTS_BUSY: 'ALL_AGENTS_BUSY',
  WIP: 'WIP',
  REJECT: 'REJECT',
};

export const VKYC_Progress = {
  workAppProgress: ['NA', 'INITIATED', 'Unable', 'Reopen', null],
  vkycProgress: ['OPEN', 'WIP', 'Unable', 'DROPPED'],
  auditorProgress: ['Successful', 'AuditorAssigned', 'AuditorReady'],
};
export const MIN_LIMIT_QR = 0.01;
export const DYNAMIC_MAX_LIMIT_QR = 2000;
export const MAX_LIMIT_QR = 9999999;
export const MAX_LIMIT_REMARK = 20;

export const qrFilterDateRange = {
  isInitialfilterByCalender: false,
  startDate: startOfDay(new Date()),
  endDate: endOfDay(new Date()),
};
export const formatDownloadButton = ['CSV'];
export const formatDownloadButtonIcons = ['csvIcon'];

export const SETTLEMENT_STATUS = [
  'INIT',
  'SETTLED',
  'ON_HOLD',
  'READY_FOR_SETTLEMENT',
  'FAILED',
  'RETRY',
  'CHARGE_SETTLED',
];
export const SETTLED = ['SETTLED', 'CHARGE_SETTLED'];
export const STANDEE = 'standee';
export const SOUNDBOX = 'soundBox';
export const STATUS_QR = {
  onHold: 'ON_HOLD',
  SETTLED: 'SETTLED',
  INIT: 'Success',
};

export const STATUS_QR_SEARCH = {
  onHold: 'ON_HOLD',
  SETTLED: 'SETTLED',
  INIT: 'INIT',
  FAILED: 'FAILED',
  SUCCESS: 'SUCCESS',
};
export const Collection_Module = 'Collection';

export const intialFilterQrDateRange = {
  isInitialfilterByCalender: false,
  startDate: startOfDay(new Date()),
  endDate: endOfDay(new Date()),
};
export const intialPriceQrFilter = {
  transactionType: 'all',
  isIntialPriceFilter: false,
  fromAmount: '',
  toAmount: '',
};
export const filterDataQrInitial = {
  accountNumber: '',
  filterDateRange: intialFilterQrDateRange,
  priceRangeFilter: intialPriceQrFilter,
};

export const willBeSettled = 'will be settled by 8 AM, Tomorrow';

export const collectionConfig = {
  CONFIG_TYPE: 'FE_COLLECTION_CONFIG',
  MODULE: 'feCollectionConfig',
};

export const LEAD_SOURCE = 'UBL01';

export const CRMLEAD_CONFIG = {
  CONFIG_TYPE: 'FE_CRM_LEAD_CONFIG',
  MODULE: 'crmLeadConfig',
};

export const TYPE_OF_CUSTOMER = {
  NTB: 'NTB',
  ETB: 'ETB',
};

export const UPIQR = 'UPIQR';

export const DateFormat = {
  from: 'YYYY-MM-DDT00:00:00.000Z',
  to: 'YYYY-MM-DDT23:59:59.000Z',
};

export const TimeFormat = {
  Time: 'YYYYMMDD',
  DateType: 'DD MMM, YYYY',
};

export const FLAG = {
  Y: 'Y',
  N: 'N',
};

export const FE_SETTLEMENT_CONFIG_TYPE = {
  FE_SETTLEMENT_CONFIG: 'FE_SETTLEMENT_CONFIG',
  MODULE: 'feSettlementConfig',
};

export const DYNAMIC_QR_EXPIRATION_TIME = '300';
