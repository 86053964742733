import { entitlement_urls } from '@src/core-navigations';

export const ent_breadcrumb = [
  { url: '/dashboard', name: 'Home', id: 1, parents: [] },
  {
    url: '',
    name: 'Entitlement management',
    id: 2,
    parents: [1],
  },

  {
    url: '',
    name: 'Employee',
    parents: [1],
    id: 3,
  },
  {
    url: '/entitlement/employee/roles-list',
    name: 'Role',
    parents: [3, 2, 1],
    id: 4,
  },
  {
    url: '/entitlement/employee/create-role',
    name: 'Create Role',
    parents: [4, 3, 2, 1],
    id: 6,
  },
  {
    url: '/entitlement/employee/modify-role',
    name: 'Modify Role',
    parents: [4, 3, 2, 1],
    id: 7,
  },
  {
    url: '/entitlement/employee/clone-role',
    name: 'Clone Role',
    parents: [4, 3, 2, 1],
    id: 8,
  },
  {
    url: '/entitlement/employee/role-details',
    name: 'Role Details',
    parents: [4, 3, 2, 1],
    id: 9,
  },
  {
    url: '/entitlement/employee/users',
    name: 'Users',
    parents: [3, 2, 1],
    id: 10,
  },
  {
    url: '/entitlement/employee/user-details',
    name: 'User Details',
    parents: [10, 3, 2, 1],
    id: 11,
  },
  {
    url: '/entitlement/employee/user-auth-group-list',
    name: 'User Group/ Auth Group',
    parents: [3, 2, 1],
    id: 12,
  },
  {
    url: '/entitlement/employee/modify-users-details',
    name: 'Modify User',
    parents: [10, 3, 2, 1],
    id: 13,
  },
  {
    url: '/entitlement/employee/create-users-details',
    name: 'Create User',
    parents: [10, 3, 2, 1],
    id: 14,
  },
  {
    url: '/entitlement/employee/modify-users',
    name: 'Modify User',
    parents: [10, 3, 2, 1],
    id: 15,
  },
  {
    url: '/entitlement/employee/create-users',
    name: 'Create User',
    parents: [10, 3, 2, 1],
    id: 16,
  },
  {
    url: '/entitlement/employee/modify-users-details',
    name: 'Modify User',
    parents: [10, 3, 2, 1],
    id: 17,
  },
  {
    url: '/entitlement/employee/modify-users',
    name: 'Modify User',
    parents: [10, 3, 2, 1],
    id: 18,
  },
  {
    url: '/entitlement/employee/add-group',
    name: 'Create User Group/ Auth Group',
    parents: [12, 3, 2, 1],
    id: 19,
  },
  {
    url: '/entitlement/employee/modify-group',
    name: 'Modify User Group/ Auth Group',
    parents: [12, 3, 2, 1],
    id: 20,
  },
  {
    url: '/entitlement/employee/user-group-details',
    name: 'User Group Details',
    parents: [12, 3, 2, 1],
    id: 21,
  },
  {
    url: '/entitlement/employee/auth-group-details',
    name: 'Auth Group Details',
    parents: [12, 3, 2, 1],
    id: 22,
  },
  {
    url: '',
    name: 'Merchant',
    parents: [1],
    id: 23,
  },
  {
    url: '/entitlement/merchant/roles-list',
    name: 'Role',
    parents: [23, 2, 1],
    id: 24,
  },
  {
    url: '/entitlement/merchant/create-role',
    name: 'Create Role',
    parents: [24, 23, 2, 1],
    id: 25,
  },
  {
    url: '/entitlement/merchant/role-details',
    name: 'Role Details',
    parents: [24, 23, 2, 1],
    id: 26,
  },
  {
    url: '/entitlement/merchant/modify-role',
    name: 'Modify Role',
    parents: [24, 23, 2, 1],
    id: 27,
  },
  {
    url: '/entitlement/merchant/clone-role',
    name: 'Clone Role',
    parents: [24, 23, 2, 1],
    id: 28,
  },
  {
    url: '',
    name: 'Merchant',
    parents: [2, 1],
    id: 29,
  },
  {
    url: '/entitlement/merchant/view',
    name: 'Merchant Details',
    parents: [47, 2, 1],
    id: 30,
  },
  {
    url: entitlement_urls?.mr_user_details_user_add,
    name: 'Users',
    parents: [23, 2, 1],
    id: 31,
  },
  {
    url: entitlement_urls?.mr_user_details_confirmation,
    name: 'Users',
    parents: [23, 2, 1],
    id: 32,
  },
  {
    url: entitlement_urls?.mr_user_account_map,
    name: 'Users',
    parents: [23, 2, 1],
    id: 33,
  },
  {
    url: entitlement_urls?.mr_user_role_map,
    name: 'Users',
    parents: [23, 2, 1],
    id: 34,
  },
  {
    url: entitlement_urls?.mr_user_auth_group_map,
    name: 'Users',
    parents: [23, 2, 1],
    id: 35,
  },
  {
    url: entitlement_urls?.mr_user_creation_review,
    name: 'Create Users',
    parents: [23, 2, 1],
    id: 36,
  },
  {
    url: '/entitlement/merchant/details',
    name: 'Create Merchant',
    parents: [23, 2, 1],
    id: 27,
  },

  {
    url: '/entitlement/auth/list',
    name: 'Auth Group',
    parents: [2, 1, 23],
    id: 28,
  },
  {
    url: '/entitlement/auth/creategroup',
    name: 'Auth Group Create',
    parents: [2, 1, 23],
    id: 29,
  },
  {
    url: '/entitlement/auth/details',
    name: 'Auth Group Create',
    parents: [2, 1, 28, 23],
    id: 29,
  },
  {
    url: '/entitlement/merchant/detailsconfirm',
    name: 'Create Merchant',
    parents: [23, 2, 1],
    id: 38,
  },
  {
    url: '/entitlement/merchant/accountmap',
    name: 'Create Merchant',
    parents: [23, 2, 1],
    id: 39,
  },
  {
    url: '/entitlement/merchant/rolelist',
    name: 'Create Merchant',
    parents: [23, 2, 1],
    id: 40,
  },
  {
    url: '/entitlement/merchant/preview',
    name: 'Create Merchant',
    parents: [23, 2, 1],
    id: 32,
  },
  {
    url: entitlement_urls?.mr_user_auth_group_map,
    name: 'Create Merchant',
    parents: [23, 2, 1],
    id: 35,
  },
  {
    url: entitlement_urls?.mr_user_creation_review,
    name: 'Create Users',
    parents: [23, 2, 1],
    id: 36,
  },
  {
    url: '/entitlement/merchant/details',
    name: 'Create Merchant',
    parents: [23, 2, 1],
    id: 37,
  },
  {
    url: '/entitlement/merchant/accountmap',
    name: 'Create Merchant',
    parents: [23, 2, 1],
    id: 39,
  },
  {
    url: '/entitlement/merchant/rolelist',
    name: 'Create Merchant',
    parents: [23, 2, 1],
    id: 40,
  },
  {
    url: '/entitlement/merchant/preview',
    name: 'Create Merchant',
    parents: [23, 2, 1],
    id: 41,
  },
  {
    url: '/entitlement/merchant/merchant-list',
    name: 'Merchant List',
    parents: [23, 2, 1],
    id: 44,
  },
  {
    url: entitlement_urls?.mr_user_details,
    name: 'Merchant User List',
    parents: [23, 30, 2, 1],
    id: 45,
  },

  {
    url: entitlement_urls?.merchant_user_details,
    name: 'User Details',
    parents: [23, 48, 2, 1],
    id: 46,
  },
  {
    url: '/entitlement/merchant/merchant-list',
    name: 'Merchant',
    parents: [1],
    id: 47,
  },
  {
    url: entitlement_urls?.mr_user_details,
    name: 'Users',
    parents: [23, 2, 1],
    id: 48,
  },
  {
    url: entitlement_urls?.auth_matrix_merchant_search,
    name: 'Authorization Matrix (Merchant List)',
    parents: [23, 2, 1],
    id: 49,
  },
];
