import React from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonProps, Icon, XB_Button, XB_Modal } from '@core-components/atoms';

interface ErrorProps {
  title?: string;
  subTitle?: string;
  btnProps?: ButtonProps;
  isOpen: boolean;
  setOpen: (args) => void;
  errorImage?: string;
}

export const Form15ErrorModal: React.FC<ErrorProps> = ({
  isOpen,
  setOpen,
  title,
  btnProps,
  errorImage,
  subTitle = '',
}: ErrorProps) => {
  const { t } = useTranslation();
  return (
    <XB_Modal
      open={isOpen}
      setOpen={(open) => setOpen(open)}
      maxWidth="25rem"
      img={<Icon icon="warningmodal" color="none" width={48} height={48} />}
      title={t(title as string)}
      subTitle={t(subTitle)}
      additionSubTitle={
        errorImage && <img src={errorImage} alt="banner" className="w-full" />
      }
      actions={
        <div className="flex flex-col gap-3">
          {btnProps && (
            <XB_Button
              classes="w-full"
              btnType="primary"
              onClick={() => setOpen(false)}
              {...btnProps}
            >
              {btnProps.children}
            </XB_Button>
          )}
          <XB_Button
            classes="w-full"
            btnType="secondary"
            dataTestId="CNCL"
            onClick={() => {
              setOpen(false);
            }}
          >
            {t('cancel')}
          </XB_Button>
        </div>
      }
    />
  );
};
