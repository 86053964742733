import { AUEndPoints, Service } from '@core-services';

export const getDasboardPersona = async (personaType: string) => {
  const reqObject = {
    baseurl: AUEndPoints.strapiService.getDashboardPersona(personaType),
    method: 'GET',
    url: '',
  };
  const response = await Service.send(reqObject);

  return response.data;
};

export const getOffersList = async () => {
  const reqObject = {
    baseurl: AUEndPoints.strapiService.getOffers,
    method: 'GET',
    url: '',
  };
  const response = await Service.send(reqObject);

  return response.data;
};

export const getDepositDetailsAdmin = async (req: { CustomerId: string }) => {
  const reqObj = {
    baseurl: AUEndPoints.depositService.DEPOSIT_DETAILS_ADMIN,
    method: 'POST',
    url: '',
    obj: req,
  };
  const response = await Service.send(reqObj);
  return response.data;
};
