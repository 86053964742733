import React from 'react';
import { useNavigate } from 'react-router-dom';

import { isEmpty } from 'lodash';

import { Icon, XB_Divider } from '@core-components/atoms';

import './AU_ProfileInfoCard.style.scss';

interface ProfileInfoCardProps {
  items: Array<{
    key: string;
    value?: string;
    prefixElement?: React.JSX.Element;
    iconName?: string;
    iconClass?: string;
    isDividerVisible?: boolean;
    element?: React.JSX.Element;
    redirection?: string;
    isURL?: boolean;
    navigationData?: any;
    callBackFunc?: () => void;
    isCardVisible?: boolean;
    isBtnClick?: boolean;
  }>;
  heading?: string;
}

export const AU_ProfileInfoCard = ({
  items,
  heading,
}: ProfileInfoCardProps) => {
  const navigate = useNavigate();

  const handleClick = (ele) => {
    if (!isEmpty(ele.navigationData)) {
      navigate(ele.redirection, { state: ele.navigationData });
    } else {
      if (ele.callBackFunc && isEmpty(ele.redirection)) {
        ele.callBackFunc();
      }
      if (ele.isURL) {
        window.open(ele.redirection, '_blank', 'noopener,noreferrer');
      } else {
        navigate(ele.redirection);
      }
    }
  };

  const renderProfileItemSection = (ele, index) => {
    return (
      <React.Fragment key={index}>
        <XB_Divider
          classes={`${
            index === 0 || ele?.isCardVisible || ele?.isDividerVisible
              ? 'hidden'
              : 'my-3'
          } divider-section${index as string}`}
        />

        <div
          className={`flex cursor-pointer justify-between items-center ${
            ele?.isCardVisible ? 'hidden' : 'block'
          }`}
          onClick={() => (ele?.isBtnClick ? null : handleClick(ele))}
          id="CARD-DIV"
        >
          {ele?.key && (
            <div className="flex items-center">
              <span
                className={ele?.iconClass ? ele?.iconClass : 'text-transparent'}
              >
                {ele?.iconName ? (
                  <Icon icon={ele?.iconName} />
                ) : (
                  ele?.prefixElement
                )}
              </span>

              <div className="ml-2">
                <h3 className="m-text-lg-medium text-gray-800">{ele?.key}</h3>
                <p className="m-text-md-regular text-gray-700 break-all">
                  {ele?.value}
                </p>
              </div>
            </div>
          )}
          {ele.element && <div className="my-auto"> {ele?.element}</div>}
        </div>
      </React.Fragment>
    );
  };

  return (
    <div className="personal_info">
      {heading && <h2 className="m-text-lg-bold text-gray-900">{heading}</h2>}
      <div className={`${heading ? 'mt-4' : 'mt-0'}`}>
        {items?.map((ele, index) => renderProfileItemSection(ele, index))}
      </div>
    </div>
  );
};
