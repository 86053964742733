import {
  AppInitState,
  DebitCardListType,
  DuplicateFileMap,
  getTheme,
  initApp,
  initDebitCardList,
  initGlobalConfig,
  initLanguageList,
} from '@core-stores';
import { createSlice } from '@reduxjs/toolkit';

const globalConfigInitial = {
  activeAccounts: '',
  otpRequestBackOff: '30',
  aadhaarOtpLength: '6',
  defaultRetryBackOff: '100',
  otpExpiry: '30',
  requestOtpAttempts: '3',
  loginAttempts: '6',
  blank: '[{"blank":{"code":null,"latest_version":null,"name":null}}]',
  smbPollInterval: '45000',
  language: '{"code":"hi","name":"हिन्दी","version":"1.0"}',
  mobileOtpLength: '6',
  latestAppVersion: '1',
  incorrectOtpAttempts: '3',
  personaCode: 'PRSN01',
  exiomSmsMobileNumbers:
    '{"1":"919990012092","2":"919899778552","3":"919829170008"}',
  languageList:
    '[{"language":{"name":"English","code":"en","latestVersion":"1.3"}},{"language":{"name":"हिन्दी","code":"hi","latestVersion":"1.3"}},{"language":{"name":"Hinglish","code":"he","latestVersion":"1.0"}}]',
  personaVersion: '1.0.0',
  incorrectOtpAttemptss: '5',
  localhost: 'http://localhost:8080/',
  appStoreUrl: 'https://{au-domain.com}/{stage-version}/init-service/',
  smbMaxAttempt: '5',
  biometricLoginAttempts: '3',
  isSimBinding: 'true',
  otp: '500',
  defaultRetryCount: '3',
  isEncryptionEnabled: 'false',
  latestVersion: '1.0.0',
  mpinLoginAttempts: '3',
  isForceUpdate: 'false',
  webUserInactivityTime: '120000',
  webUserAutoRefreshThreshold: '90',
  webOtpLength: '6',
  webOtpSeparatorLength: '3',
  pollAPIMaxTriesLimit: '4',
  pollAPIHitInterval: '30',
  allowDuplicateFileInOnboarding: DuplicateFileMap.ALLOW_DUPLICATE,
  mobile_update: false,
  isNTBAllowQR: false,
  gst_ifsc: '',
  direct_tax_to_account_holder_name: '',
  direct_tax_ifsc: '',
  isETBAllowQR: false,
  isCKYCRequired: false,
};

const initialState: AppInitState = {
  globalConfig: globalConfigInitial,
  urlConfig: undefined,
  isEncryptionEnabled: false,
  appUpdate: undefined,
  registration: undefined,
  language: undefined,
  available_languages: undefined,
  debitCardList: [],
  appType: '',
  browserNavigation: {
    global: true,
    page: false,
  },
  theme: {
    themeType: 'Light',
    navThemeType: 'store',
    config: undefined,
  },
};

const initSlice = createSlice({
  name: 'init',
  initialState,
  reducers: {
    setThemeType: (state, { payload }) => {
      state.theme = { ...state.theme, themeType: payload };
    },
    setNavThemeType: (state, { payload }) => {
      state.theme = { ...state.theme, navThemeType: payload };
    },
    setUserType: (state, { payload }) => {
      state.appType = payload;
    },
    setBrowserNavigation: (state, { payload }) => {
      state.browserNavigation = { ...state.browserNavigation, ...payload };
    },
  },
  extraReducers: (builder) => {
    const setRejectedConfig = (state) => {
      state.globalConfig = globalConfigInitial;
      state.urlConfig = undefined;
      state.isEncryptionEnabled = false;
      state.appUpdate = undefined;
      state.registration = undefined;
      state.language = undefined;
      state.available_languages = undefined;
    };

    builder.addCase(initApp.fulfilled, (state: AppInitState, { payload }) => {
      ({
        globalConfig: state.globalConfig,
        urlConfig: state.urlConfig,
        isEncryptionEnabled: state.isEncryptionEnabled,
        appUpdate: state.appUpdate,
        registration: state.registration,
        language: state.language,
        available_languages: state.available_languages,
      } = payload.data);
    });
    builder.addCase(initApp.rejected, (state) => {
      setRejectedConfig(state);
    });
    builder.addCase(initDebitCardList.fulfilled, (state, { payload }) => {
      const debitCardResp = payload?.data;
      const debitCardListRecord: DebitCardListType[] = debitCardResp;
      state.debitCardList = debitCardListRecord;
    });
    builder.addCase(initDebitCardList.rejected, (state) => {
      state.debitCardList = [];
    });
    builder.addCase(
      initLanguageList.fulfilled,
      (state: AppInitState, { payload }) => {
        state.available_languages = JSON.parse(
          payload?.data?.language_list ??
            '[{"language":{"code":"en","latest_version":"1.0","name":"English"}}]'
        );
      }
    );
    builder.addCase(initLanguageList.rejected, (state) => {
      state.available_languages = undefined;
    });
    builder.addCase(getTheme.pending, (state) => {
      state.theme = { ...state.theme, config: undefined };
    });
    builder.addCase(getTheme.fulfilled, (state, { payload }) => {
      state.theme = { ...state.theme, config: payload?.data };
    });
    builder.addCase(getTheme.rejected, (state) => {
      state.theme = { ...state.theme, config: undefined };
    });
    builder.addCase(
      initGlobalConfig.fulfilled,
      (state: AppInitState, { payload }) => {
        if (payload.data?.allowDuplicateFileInOnboarding) {
          switch (payload.data?.allowDuplicateFileInOnboarding) {
            case '0':
              payload.data.allowDuplicateFileInOnboarding =
                DuplicateFileMap.NO_DUPLICATE;
              break;
            case '1':
              payload.data.allowDuplicateFileInOnboarding =
                DuplicateFileMap.OVERRIDE_DUPLICATE;
              break;
            case '2':
            default:
              payload.data.allowDuplicateFileInOnboarding =
                DuplicateFileMap.ALLOW_DUPLICATE;
          }
        }

        state.available_languages = JSON.parse(payload?.data?.languageList);
        state.globalConfig = payload.data;
      }
    );
    builder.addCase(initGlobalConfig.rejected, (state) => {
      setRejectedConfig(state);
    });
  },
});

export const {
  setThemeType,
  setNavThemeType,
  setUserType,
  setBrowserNavigation,
} = initSlice.actions;
export default initSlice.reducer;
