import { useEffect } from 'react';
import DatePicker from 'react-date-picker';

import { Icon } from '../icon/XB_Icon.component';

import './AU_DateSelector.style.scss';

interface DateSelectorProps {
  label?: string;
  id: string;
  classes?: string;
  value: any;
  handleChange: (date) => void;
  disabled?: boolean;
  minDate?: string;
  maxDate?: string;
  name?: string;
  showClearIcon?: boolean;
  format?: string;
}

export const AU_DateSelector = ({
  id,
  label,
  value,
  handleChange,
  disabled = false,
  classes,
  minDate,
  maxDate,
  name,
  showClearIcon,
  format = 'dd/MM/yyyy',
  ...otherprops
}: DateSelectorProps) => {
  useEffect(() => {
    if (showClearIcon) {
      document?.getElementById(`${id}`)?.classList.add('cross-icon-show');
    }
  }, [showClearIcon, handleChange]);
  useEffect(() => {
    const inputs = document.querySelectorAll(
      '.react-date-picker__inputGroup input'
    );
    inputs.forEach((input) => {
      (input as HTMLInputElement).readOnly = true;
    });
  }, []);

  return (
    <div className={`au_date_selector_container ${classes}`}>
      {label && (
        <h1 className="label_style m-text-md-regular text-gray-700 mb-[0.375rem]">
          {label}
        </h1>
      )}
      <DatePicker
        id={id}
        onChange={(date) => handleChange(date)}
        value={value}
        disabled={disabled}
        clearIcon={
          showClearIcon ? <Icon icon="cross" width={16} height={16} /> : <></>
        }
        yearPlaceholder="yyyy"
        monthPlaceholder="mm"
        dayPlaceholder="dd"
        calendarIcon={<Icon icon="calendar" />}
        isOpen={false}
        className={`au-date-picker`}
        maxDate={new Date(maxDate as string)}
        minDate={new Date(minDate as string)}
        name={name ?? ''}
        format={format}
        {...otherprops}
      />
    </div>
  );
};
