const { REACT_APP_API_BASE_URL } = process.env;
export const appConstants = {
  DEBOUNCE_TIME: 800,
  VPA_MIN_LENGTH: 5,
  isOtpEnabled: true,
  relationShipList: [
    { value: 'AUNT', text: 'Aunt' },
    { value: 'BROTHER', text: 'Brother' },
    { value: 'COUSIN', text: 'Cousin' },
    { value: 'DAUGHTER', text: 'Daughter' },
    { value: 'FATHER', text: 'Father' },
    { value: 'GRAND FATHER', text: 'Grand Father' },
    { value: 'GRAND MOTHER', text: 'Grand Mother' },
    { value: 'GRAND SON', text: 'Grand Son' },
    { value: 'GRAND DAUGHTER', text: 'Grand Daughter' },
    { value: 'GUARDIAN', text: 'Guardian' },
    { value: 'HUSBAND', text: 'Husband' },
    { value: 'MOTHER', text: 'Mother' },
    { value: 'SISTER', text: 'Sister' },
    { value: 'SON', text: 'Son' },
    { value: 'UNCLE', text: 'Uncle' },
    { value: 'WIFE', text: 'Wife' },
  ],
  MMDDYYYY: 'MM/DD/YYYY',
  DDMMYYYY_WS: 'DD/MM/YYYY', // date format with forward slash
  DDMMYYYY_WH: 'DD-MM-YYYY', // date format with forward hyphen
  YYYYMMDD_WH: 'YYYY-MM-DD', // date format with forward hyphen
  DDMMYY_WH: 'DD-MMM-YY',
  ISO_DATE_FORMAT: 'YYYY-MM-DDTHH:mm:ss.sss[Z]',
  ISO_DATE_FORMAT1: 'YYYY-MM-DDTHH:mm:ss.ssssssZ',
  TIME_HH_MM_SS: 'HH:mm:ss',
  ddMMyyyy: 'dd/MM/yyyy',
  DDMMMYYYY: 'DD-MMM-YYYY',
  DMMMYYYY: 'D MMM, YYYY',
  alertAutoCloseTime: 5000,
  YES: 'Y',
  NO: 'N',
  GENDER_OPTIONS: {
    M: 'male',
    F: 'female',
  },
  AWSBUCKETS: {
    MSMS: 'onboarding-service/so/msme-documents',
    MRMSMS: 'onboarding-service/mr/msme-documents',
    LIVE: 'onboarding-service/so/livephoto',
    LIVEMR: 'onboarding-service/mr/livephoto',
    CPC: 'case-management-service/cpc/case-documents',
    SO: 'case-management-service/so/case-documents',
    MR: 'case-management-service/mr/case-documents',
  },
  AWSBUCKETNAME: '039912797956-dev',
  DEFAULT_DATA_TABLE_PAGE_SIZE: 10,
  dateFormat: 'dd/MMM/y',
  dateTimeFormat: 'dd/MMM/y',
  APP_PLATFORM: 'WEB_APP',
  USER_SELF: 'SELF',
  dateFormatMonthYear: 'MMMM YYYY',
  dateFormatWithCommaSpace: 'DD MMM, YYYY',
  dateFormatWithCommaSpace2: 'DD MMM, YYYY',
  dateFormatOnlyYear: 'YYYY',
  dateFormatOnlyMonth: 'MMMM',
  serverDateFormat: 'YYYY-MM-DDThh:mm:ss',
  SERVER_DATE_WITH_Z: 'YYYY-MM-DDThh:mm:ssZ',
  serverDateTimeFormat_1: 'YYYY-MM-DD hh:mm:ss',
  appDateTimeFormat: 'DD/MM/YYYY - hh:mm a',
  timeWithMedian: 'hh:mm A',
  timeWithMeridianNoSpace: 'HH:mmA',
  downtimeFormat: 'YYYY-MM-DD:HH:mm:ss',
  localDowntimeFormat: 'DD MMM YYYY h:mm A',
  currentOfferDateTimeFormat: 'YYYY-MM-DDTHH:mm:ss',
  dateMonthAndTimeFormat: 'DD MMM YYYY, h:mm A',
  AUMerchant: 'AUMerchant',
  RELATIONSHIP_TYPE: 'SOW',
  CONSTITUTION_TYPE: {
    IND: 'IND',
    SOLPROP: 'SOLPROP',
    OTHER: 'OTH',
  },
  CUSTOMER_RELATION: {
    SOW: 'SOW',
    AUS: 'AUS',
  },
  CONSTITUTION_VALUE: {
    INDIVIDUAL: 'Individual',
    SOLEPROP: 'Sole Prop',
  },
  APP_TYPE: {
    MR: 'MR',
    SO: 'SO',
    ADMIN: 'ADMIN',
    CPC: 'CPC',
  },
  APP_IDENTIFIER: {
    WEB_MERCHANT: 'WEB_MERCHANT',
    WEB_SO: 'WEB_SO',
    ADMIN: 'ADMIN',
  },
  POE_TYPE: {
    POE1: 'POE1',
    POE2: 'POE2',
    POE1_POE2: 'POE1_POE2',
  },
  VERIFICATION_STATUS: {
    VERIFIED: 'VERIFIED',
    PENDING: 'PENDING',
  },
  STEP_STATUS: {
    ACTIVE: 'active',
    DONE: 'done',
    ERROR: 'error',
    TODO: 'todo',
  },
  POE_DOCUMENT_TYPE: {
    API: 'API',
    MANUAL: 'MANUAL',
  },
  POE_DOCUMENT_NAME: {
    GSTIN: 'GSTIN',
    NMC: 'NMC (National Medical Commission)',
    SEC: 'Shop & Establishment Certificate/License (S&EC)',
  },
  POE_FIELD_RELATIONSHIP: {
    MANDATORY: 'Mandatory',
  },
  CUSTOMER_TYPE: {
    NTB: 'NTB',
    ETB: 'ETB',
    SP: 'SP',
  },
  BANK_ACCOUNT_SOURCE: {
    MANUAL: 'MANUAL',
    IMPS35: 'IMPS35',
    NONE: 'NONE',
  },
  AU_ALERT_POSITION: {
    top_right: 'top_right',
    top_left: 'top_left',
    top_center: 'top_center',
    bottom_right: 'bottom_right',
    bottom_left: 'bottom_left',
    bottom_center: 'bottom_center',
  },
  BREAKPOINTS: {
    MOBILE: 640,
  },
  ACCOUNT_SOURCE: {
    CUSTOMER_360: 'CUSTOMER_360',
  },
  CREDIT: 'C',
  DEBIT: 'D',
  SOURCE_OR_CHANNEL: 'WEB',
  FIELD_SHORT_NAME: {
    DOCUMENT_URL: 'DOCUMENT_URL',
    DOCUMENT_NAME: 'DOCUMENT_NAME',
    DOCUMENT_NUMBER: 'DOCUMENT_NUMBER',
    ENTITY_NAME: 'ENTITY_NAME',
    MEDICAL_COUNCIL: 'MEDICAL_COUNCIL',
    REGION: 'REGION',
    REGISTRATION_YEAR: 'REGISTRATION_YEAR',
    EXPIRY_DATE: 'EXPIRY_DATE',
    AREA: 'AREA',
    INCORPORATION_DATE: 'INCORPORATION_DATE',
    CIFSELECTION: 'CIFSELECTION',
    INACTIVE: 'INACTIVE',
    QR: 'QR',
    VKYC_URL: 'VKYC_URL',
  },
  POE_FIELD_TYPE: {
    TEXT: 'Text',
    DATE_TYPE: 'DateType',
    DROPDOWN: 'Dropdown',
    YEAR_CALENDER: 'Year Calender',
  },
  SVR: 'SVR & Annexure 12',
  CONSENT_IDENTIFIER: {
    AADHAR_CONSENT: 'aadhar-consent',
    BANK_TNC: 'bank-tnc',
    FATCA: 'fatca',
    FULL_KYC: 'full-kyc',
    PAN_CONSENT: 'pan-consent',
    DOB_CONSENT: 'dob',
  },
  CONSENT_STATUS: {
    REVOKED: 'REVOKED',
    GRANTED: 'GRANTED',
    DENIED: 'DENIED',
    EXPIRED: 'EXPIRED',
  },
  CHANNEL: 'AUMBWeb',
  CHANNEL_MB: 'AUMBWeb',
  CHANNEL_VIDEO_BANKING: 'AUMBWeb',
  CHANNEL_PERSONAL_LOAN: 'AUMBWeb',
  CHANNEL_REF_PERSONAL_LOAN: 'AUMBWeb',
  CASE_STATUS: {
    inProgress: 'IN_PROGRESS',
    completed: 'COMPLETED',
    processed: 'PROCESSED',
    autoApproved: 'AUTO_APPROVED',
    approved: 'APPROVED',
    rejected: 'REJECTED',
    sentForRework: 'SENT_FOR_REWORK',
    sentForRefer: 'SENT_FOR_REFER',
    reworkReverted: 'REWORK_REVERTED',
    pepCheck: 'PEP_CHECK',
    fresh: 'FRESH',
    reviewPending: 'REVIEW_PENDING',
    amlCheck: 'AML_CHECK',
    wipInProgress: 'WIP_IN_PROGRESS',
  },
  DOCUMENT_TYPE: {
    AADHAR: 'AADHAAR',
  },
  MISC_CONSTANTS: {
    Others: 'Others',
    ADDRESS: 'ADDRESS',
    STOP: 'STOP',
    Yes: 'Yes',
    No: 'No',
    Business: 'Business',
    PANDEDUPE: 'PANDEDUPE',
    USERTYPE: 'USERTYPE',
  },
  BUSINESS_CONSTANTS: {
    businessNameMinLength: 2,
    businessNameMaxLength: 50,
    businessDescMinLength: 5,
    businessDescMaxLength: 55,
    businessUrlMinLength: 10,
    businessUrlMaxLength: 150,
    motherNameMinLength: 2,
    motherNameMaxLength: 20,
    fatherNameMinLength: 2,
    fatherNameMaxLength: 20,
    emailMinLength: 2,
    emailMaxLength: 40,
    expiryTimer: 120,
    resendTimer: 120,
  },
};

export const listingTray = {
  WIP: 'WIP',
};

export const appCharLimit = {
  MIN_CHAR_IFSC: 11,
  MAX_CHAR_IFSC: 11,
  MIN_ACC_NUMBER: 3,
  MAX_ACC_NUMBER: 30,
  MIN_EMAIL: 3,
  MAX_EMAIL: 120,
  MIN_FATHER_NAME: 2,
  MAX_FATHER_NAME: 20,
  MIN_MOTHER_NAME: 2,
  MAX_MOTHER_NAME: 20,
  MIN_BUSINESS_NAME: 2,
  MAX_BUSINESS_NAME: 50,
  MIN_BUSINESS_DESC: 2,
  MAX_BUSINESS_DESC: 55,
  MIN_BUSINESS_URL: 10,
  MAX_BUSINESS_URL: 150,
  MIN_QR_DISPLAY_NAME: 2,
  MAX_QR_DISPLAY_NAME: 35,
  MAX_POE_ENTITY_LENGTH: 150,
  MAX_POE_DOC_NAME: 35,
  MAX_MOBILE_NUMBER: 10,
  MAX_IFSC_CODE: 11,
  MAX_PINCODE: 6,
  MAX_REASON: 32,
  MAX_VPA_ID: 30,
  MAX_CHEQUE_NO: 6,
  MAX_ADDRESS_LINE: 35,
  MAX_DOCUMENT_REASON: 250,
  MAX_DOCUMENT_NAME: 35,
  MAX_PAN_NO: 10,
  MAX_PAN_NAME: 85,
  MIN_PAN_NAME: 2,
  MAX_NAME: 250,
  MAX_USERNAME: 32,
  MAX_BENEFICIARY_NO: 14,
  MAX_PASSWORD: 32,
  MIN_CHAR_CIF: 8,
  MAX_CHAR_CIF: 8,
};

export const sortDirectionForTable = {
  asc: 'asc',
  desc: 'desc',
};

export const checkboxState = {
  CHECKED: 'checked',
  UNCHECKED: 'unchecked',
  INDETERMINATE: 'indeterminate',
};

export const vkyc = {
  screen: {
    VIDEOKYCSCREEN: 'VIDEOKYCSCREEN',
    PENDING: 'PENDING',
    SUCCESS: 'SUCCESS',
    INPROGRESS: 'INPROGRESS',
    REJECTED: 'REJECTED',
    SCHEDULE: 'SCHEDULE',
  },
  status: {
    OPEN: 'OPEN',
    WIP: 'WIP',
    UNABLE: 'Unable',
    DROPPED: 'DROPPED',
    INITIATED: 'INITIATED',
    NA: 'NA',
    CLOSED: 'CLOSED',
    APPROVED: 'Approved',
    NOT_APPROVED: 'NotApproved',
    REJECTED: 'Rejected',
    SUCCESSFUL: 'Successful',
    AUDITOR_ASSIGNED: 'AuditorAssigned',
    AUDITOR_READY: 'AuditorReady',
    ALL_AGENTS_BUSY: 'ALL_AGENTS_BUSY',
    REOPEN: 'Reopen',
  },
};

export const Account_Status_Types = {
  ACTIVE: 'Active',
  BLOCKED: 'Blocked',
};

export const ACCOUNT_STATUS_CODES = {
  paymentactive: ['6', '8'],
  active: ['3', '4', '6', '8', '16', '17'],
  inactive: ['9', '13', '14', '15'],
  dormant: ['7', '10', '11', '12', '18', '19'],
  closed: ['1', '5', '20'],
  blocked: ['2'],
  unclaimed: ['21'],
  deposit_opening: ['4', '6', '8', '16', '17'],
  deposit_closure: ['3', '6', '8', '16', '17'],
};

export const STATUS_MATCH_KEYS = {
  CurrentStatus: 'CurrentStatus',
  DepStat: 'DepStat',
};

export const ACCOUNT_STATUS = {
  active: 'active',
  inactive: 'inactive',
  dormant: 'dormant',
  closed: 'closed',
  blocked: 'blocked',
  unclaimed: 'unclaimed',
};

export const THEME_TYPE = {
  LIGHT: 'Light',
  DARK: 'Dark',
};

export const navigationDirection = {
  PREV: 'PREV',
  NEXT: 'NEXT',
};

export const DASHBOARD_TYPE = {
  BANK: 'BANK',
  STORE: 'STORE',
};
export const contactSupport = 'https://www.aubank.in/customer-support';
export const tncRedirection = 'https://www.aubank.in/terms-and-conditions';
export const aboutMerchantRedirection = 'https://mib.aubank.in/about-us';
export const disclaimerRedirection = 'https://www.aubank.in/disclaimer';
export const disclaimerRedirection1 = 'https://www.aubank.in/disclaimer1';
export const securityTipRedirection =
  'https://www.aubank.in/support/contact-us';
export const ppRedirection = 'https://www.aubank.in/privacy-policy';
export const auroChatRedirection =
  'https://www.aubank.in/personal-banking/auro';
export const auBankWhatsappRedirection =
  'https://api.whatsapp.com/send?phone=918383838399&text=Hi';
export const branchLocatorRedirection = 'https://www.aubank.in/branch-locator';
export const insuranceNTBRedirection =
  'https://www.aubank.in/personal-banking/sme-insurance';
export const investmentNTBRedirection =
  'https://www.aubank.in/personal-banking/sme-insurance';
export const redirectionNTBNPS =
  'https://www.aubank.in/personal-banking/national-pension-system';
export const redirectionDeposit =
  'https://aucadiy.aubank.in/?utm_source=merchant-app&utm_medium=web&utm_campaign=account-summary-screen';
export const redirectionFD =
  'https://www.aubank.in/personal-banking/term-deposits/fixed-deposits/interest-rates';
export const redirectionSOT =
  'https://www.aubank.in/personal-banking/term-deposits/fixed-deposits/interest-rates';
export const redirectionRD =
  'https://www.aubank.in/personal-banking/term-deposits/recurring-deposits/interest-rates';
export const redirectionNTB_MutualFund =
  'https://www.aubank.in/personal-banking/mutual-funds';
export const redirectionNTB_IPO = 'https://www.aubank.in/personal-banking/asba';
export const redirectionNTB_EquityTrading =
  'https://www.aubank.in/personal-banking/3-in-1-account';
export const redirectionNPS =
  'https://www.aubank.in/personal-banking/national-pension-system';
export const personalLoanNTBRedirection =
  'https://www.aubank.in/personal-banking/personal-loan';

export const ACCOUNT_TYPES = {
  SavingsAccount: {
    strapiKey: 'savingAccount',
    code: 'SavingsAccount',
  },

  CurrentAccount: {
    strapiKey: 'currentAccount',
    code: 'CurrentAccount',
  },
  Overdraft: {
    strapiKey: 'overdraft',
    code: 'Overdraft',
  },
  CashCredit: {
    strapiKey: 'cashCredit',
    code: 'CashCredit',
  },
  FixedDeposit: {
    strapiKey: 'fixedDeposit',
    code: 'FixedDeposit',
  },
  RecurringDeposit: {
    strapiKey: 'recurringDeposit',
    code: 'RecurringDeposit',
  },
  TaxSavingDeposit: {
    strapiKey: 'taxSavingDeposit',
    code: 'TaxSavingDeposit',
  },
  Loans: {
    strapiKey: 'loans',
    code: 'Loans',
  },
};
export const startekConstants = {
  timeOutErrCode: '730',
  timeOutErrValue: 'Capture Failed / TimeOut',
  connectionFailed: '100',
  deviceNotReadyCode: '720',
  deviceNotReadyInfo: 'Device not ready.',
};

export const evoluteConstants = {
  timeOutErrCode: '700',
  timeOutErrValue: 'CAPTURE TIMED OUT',
  connectionFailed: '999',
  connectionFailedValue: 'Device Not Connected',
};
export const docName = {
  FRONT_SIDE: 'Front Side',
  UPLOAD_SVR: 'Upload SVR',
  UPLOAD_ANNEXURE_12: 'Upload Annexure 12',
};

export enum ConfigType {
  EMAIL_OTP_CONFIG = 'EMAIL_OTP_CONFIG',
  SETTLEMENT_CONFIG = 'SETTLEMENT_CYCLE_MASTER_CONFIG',
  ONBOARDING_CONFIG = 'ONBOARDING_CONFIG',
}

export const ONBOARDING_CONFIG_KEY = {
  ONBOARDING_CONFIG: 'ONBOARDING_CONFIG',
  MODULE: 'onboardingConfig',
};

export const AuthLayout_footer_links = [
  {
    title: 'auBankAllRightsReserved',
    url: '',
  },
  {
    title: 'disclaimer',
    url: disclaimerRedirection1,
  },
  {
    title: 'privacyPolicy',
    url: ppRedirection,
  },
  {
    title: 'termsAndConditions',
    url: tncRedirection,
  },
  {
    title: 'securityTips',
    url: securityTipRedirection,
  },
  {
    title: 'Version-1.0.12',
    url: '',
  },
];

export const customerRelations = {
  AUS: 'AUS',
};

export const countryCode = '+91';

export const userType = {
  MR: 'MR',
  SO: 'SO',
  MERCHANT: 'MERCHANT',
  DEFAULT: 'DEFAULT',
};
export const CROSS_SELL_NAME = {
  VIDEO_BANKING: 'VB_WELCOME_PAGE',
  MOBILE_BANKING: 'VB_UPDATE_MOBILE_NO_PAGE',
  WORKFLOW_GROUP_ID: 'NTB_PRELOGIN_VB',
  CLEAR_TAX: 'CLEAR_TAX',
  REDO_KYC: 'RE_KYC_VIDEO_BANKING',
  POSH_VINE: 'POSH_VINE',
};

export const serviceUrlArr = [
  `${REACT_APP_API_BASE_URL}/api/config-service/config/feGet`,
  `${REACT_APP_API_BASE_URL}/api/deposit-service/mapped-customer-accounts-details`,
  `${REACT_APP_API_BASE_URL}/api/deposit-service/get-deposit-label`,
  `${REACT_APP_API_BASE_URL}/api/deposit-service/get-nominee`,
  `${REACT_APP_API_BASE_URL}/api/deposit-service/product-codes-ind`,
  `${REACT_APP_API_BASE_URL}/api/deposit-service/get-td-calculation`,
  `${REACT_APP_API_BASE_URL}/api/deposit-service/get-rd-calculation`,
  `${REACT_APP_API_BASE_URL}/api/deposit-service/deposit-creation`,
  `${REACT_APP_API_BASE_URL}/api/deposit-service/rd-enquiry`,
  `${REACT_APP_API_BASE_URL}/api/deposit-service/td-enquiry`,
  `${REACT_APP_API_BASE_URL}/api/deposit-service/rd-closure`,
  `${REACT_APP_API_BASE_URL}/api/deposit-service/td-closure`,
  `${REACT_APP_API_BASE_URL}/api/deposit-service/add-nominee`,
  `${REACT_APP_API_BASE_URL}/api/deposit-service/casa-summary`,
  `${REACT_APP_API_BASE_URL}/api/deposit-service/deposit-summary`,
  `${REACT_APP_API_BASE_URL}/api/deposit-service/get-td-deposit`,
  `${REACT_APP_API_BASE_URL}/api/deposit-service/update-nominee`,
  `${REACT_APP_API_BASE_URL}/api/deposit-service/change-nominee`,
  `${REACT_APP_API_BASE_URL}/api/deposit-service/set-purpose`,
  `${REACT_APP_API_BASE_URL}/api/deposit-service/get-customer-details`,
  `${REACT_APP_API_BASE_URL}/api/deposit-service/get-all-financial-years`,
  `${REACT_APP_API_BASE_URL}/api/deposit-service/customer-account-relationship`,
  `${REACT_APP_API_BASE_URL}/api/debit-card-service/debit-card-details`,
  `${REACT_APP_API_BASE_URL}/api/debit-card-service/debit-card-limit`,
  `${REACT_APP_API_BASE_URL}/api/debit-card-service/international-domestic-usage`,
  `${REACT_APP_API_BASE_URL}/api/debit-card-service/get-rewards-balance-points`,
  `${REACT_APP_API_BASE_URL}/api/debit-card-service/sso-redirection`,
  `${REACT_APP_API_BASE_URL}/api/debit-card-service/update-debit-card-details`,
  `${REACT_APP_API_BASE_URL}/api/debit-card-service/get-debit-card-cvv`,
  `${REACT_APP_API_BASE_URL}/api/debit-card-service/set-debit-card-pin`,
  `${REACT_APP_API_BASE_URL}/api/debit-card-service/update-debit-card`,
  `${REACT_APP_API_BASE_URL}/api/content-exporter-service/interest-certificate-download`,

  `${REACT_APP_API_BASE_URL}/api/csr-service/fetch-cheque-details`,
  `${REACT_APP_API_BASE_URL}/api/csr-service/request-stop-cheque`,
  `${REACT_APP_API_BASE_URL}/api/csr-service/sr-form-15gh`,
  `${REACT_APP_API_BASE_URL}/api/csr-service/request-cheque-book`,
  `${REACT_APP_API_BASE_URL}/api/csr-service/update-customer-email`,
  `${REACT_APP_API_BASE_URL}/api/csr-service/sr-status`,
  `${REACT_APP_API_BASE_URL}/api/csr-service/positive-pay`,
  `${REACT_APP_API_BASE_URL}/api/csr-service/get-sr-list`,
  `${REACT_APP_API_BASE_URL}/api/csr-service/list-positive-pay`,
  `${REACT_APP_API_BASE_URL}/api/csr-service/positive-pay-status`,
  `${REACT_APP_API_BASE_URL}/api/csr-service/crm-lead-generation`,
  `${REACT_APP_API_BASE_URL}/api/csr-service/update-communication-address`,
  `${REACT_APP_API_BASE_URL}/api/csr-service/video-banking/submit`,
  `${REACT_APP_API_BASE_URL}/api/csr-service/cross-sell/submit`,
  `${REACT_APP_API_BASE_URL}/api/csr-service/cross-sell/equity`,
  `${REACT_APP_API_BASE_URL}/api/csr-service/loginsso-ipo-redirection`,
  `${REACT_APP_API_BASE_URL}/api/csr-service/fraud-and-dispute`,

  `${REACT_APP_API_BASE_URL}/api/payments-service/loan-overdue-payment`,
  `${REACT_APP_API_BASE_URL}/api/payments-service/loan-account-inquiry`,
  `${REACT_APP_API_BASE_URL}/api/payments-service/loan-account-statement`,

  `${REACT_APP_API_BASE_URL}/api/payments-service/link-payee/withdraw-allowance`,
  `${REACT_APP_API_BASE_URL}/api/payments-service/link-payee/set-allowance`,
  `${REACT_APP_API_BASE_URL}/api/payments-service/link-payee/get-allowances`,
  `${REACT_APP_API_BASE_URL}/api/payments-service/link-payee/get-allowance-detail-list`,
  `${REACT_APP_API_BASE_URL}/api/payments-service/link-payee/cancel-allowance-or-txn`,

  `${REACT_APP_API_BASE_URL}/api/payments-service/get-payee`,
  `${REACT_APP_API_BASE_URL}/api/payments-service/verify-payee-name`,
  `${REACT_APP_API_BASE_URL}/api/payments-service/update-payee-details`,
  `${REACT_APP_API_BASE_URL}/api/payments-service/payee-account-validation`,
  `${REACT_APP_API_BASE_URL}/api/payments-service/deactivate-payee`,
  `${REACT_APP_API_BASE_URL}/api/payments-service/add-payee`,

  `${REACT_APP_API_BASE_URL}/api/payments-service/monthly-account-balance`,

  `${REACT_APP_API_BASE_URL}/api/payments-service/account-periodic-statement`,
  `${REACT_APP_API_BASE_URL}/api/payments-service/account-mini-statement`,

  `${REACT_APP_API_BASE_URL}/api/payments-service/make-payment`,
  `${REACT_APP_API_BASE_URL}/api/payments-service/get-payments-in-series`,
  `${REACT_APP_API_BASE_URL}/api/payments-service/get-transaction-list`,
  `${REACT_APP_API_BASE_URL}/api/payments-service/freq-payee-list`,
  `${REACT_APP_API_BASE_URL}/api/payments-service/cancel-payment`,
  `${REACT_APP_API_BASE_URL}/api/payments-service/internal-account-verification`,
  `${REACT_APP_API_BASE_URL}/api/payments-service/account-verification-self`,
  `${REACT_APP_API_BASE_URL}/api/payments-service/account-verification`,
  `${REACT_APP_API_BASE_URL}/api/payments-service/payee-casa-accounts`,
];

export const excludedSidebarList = ['/fraud-dispute-status'];

export const MERCHANT_APP_CHANNEL = 'MERCHANT_APP';
export const TEST_ID = 'TETSTS-0901';

export const VERIFICATION_MODE = {
  OTP_MOBILE: 'OTP_MOBILE',
  OTP_EMAIL: 'OTP_EMAIL',
  DEBIT_CARD: 'DEBIT_CARD',
  FACE_MATCH: 'FACE_MATCH',
  PASSWORD: 'PASSWORD',
  MPIN: 'MPIN',
};

export const CREDENTIAL_TYPE = {
  PASSWORD: 'PASSWORD',
};

export const videoinput = 'videoinput';
export const CAMERA_ACCESS = {
  CAN_USE_CAMERA: 'CAN-USE-CAMERA',
  PERMISSION_DENIED: 'PERMISSION-DENIED',
  UNABLE_TO_ACCESS_CAMERA: 'UNABLE-TO-ACCESS-CAMERA',
};
export const CAMERA_PERMISSION_ERROR = {
  NotAllowedError: 'NotAllowedError',
  NotReadableError: 'NotReadableError',
};

export const KNOW_MORE =
  'https://www.aubank.in/personal-banking/digital-banking/merchant-payment-services/upi-qr-payment';
export const POSITIVE_PAY_URL = 'https://www.aubank.in/au-positive-pay';
export const NTB_VIDEO_BANKING =
  'https://www.aubank.in/personal-banking/digital-banking/video-banking';

export const NTB_OFFER_LINK = 'https://offers.aubank.in';
export const HELP_AND_SUPPORT = 'https://www.aubank.in/customer-support';
export const downloadStatementEvent = 'APP_AS_EMAIL';
export const strapiLangVersion = '1.0';
export const branchLocatorURL = 'https://www.aubank.in/branch-locator';
export enum redirectionCASA {
  CA = 'https://aucadiy.aubank.in/?utm_source=merchant-app&utm_medium=web&utm_campaign=dashboard-screen',
  SA = 'https://savingsaccount.aubank.in/saself/mobile-number?utm_source=merchant-app&utm_medium=web&utm_campaign=dashboard-screen',
  UBL = 'https://ubl.aubank.in/customerapp/login?source=UBL01&utm_source=merchant-app&utm_medium=web&utm_campaign=applynow',
}

export const MERCHANT_DASHBOARD = '/mr-dashboard';
export const QR_ONBOARDING_NAV = '/onboarding/qr';
export const SO_DASHBOARD = '/so-dashboard';
export const BM_DASHBOARD = '/bm-dashboard';
export const BM_PASTALLOTMENT = '/bm-past-allotment';
export const BM_RAISEREQUEST = '/bm-raise-request';
export const BM_ALLOCATION = '/bm-allocation';
export const FULL_REPORT = '/full-report';
export const VIEW_REPORT = '/view-report';
export const ALLOCATION_HISTORY = '/allocation-history';
export const PROFILE_SETTING = '/profile/home';
export const ACCOUNT_STATEMENT = '/accountstatement';
export const MONEY_TRANSFER = '/payments/moneytransfer';
export const DEPOSIT_DASHBOARD = '/deposit/dashboard';
export const FORM_15 = 'form-15g-h';
export const redirectionUTM = {
  PATH: {
    SA: 'https://savingsaccount.aubank.in/saself/mobile-number?utm_source=merchant-app&utm_medium=banner&utm_campaign=',
    CA: 'https://aucadiy.aubank.in/?utm_source=merchant-app&utm_medium=banner&utm_campaign=',
  },
  CAMPAIGN: {
    dashboardApplyNow: 'dashboard-apply-now',
    accountSummaryScreen: 'account-summary-screen',
  },
};
export const validTillConsentDate = '2024-08-29T13:05:24.735Z';
export const consentType = 'GRANTED';
export const constitutionTypeCodeData = 'IND';
export const NotificationReadStatus = 'READ';
export const Handler = '@aubank';

export const settlementType = {
  REAL_TIME: 'REAL_TIME',
  ON_DEMAND: 'ON_DEMAND',
  BOTH: 'BOTH',
};

export const ETB_CA = 'https://www.aubank.in/apply-now';

export const HOME_LOAN = 'https://www.aubank.in/personal-banking/home-loan';

export const CIF_TYPE = {
  BUSINESS: 'BUSINESS',
  PERSONAL: 'PERSONAL',
};

export const FORM_EMAIL_LIMIT = 120;
export const IFSC_CODE_LIMIT = 4;

export const USER_TYPE = {
  NTB: 'NTB',
  ETB: 'ETB',
};

export const noEmailDomain = '@noauemail.com';

export const AU_IFSC = 'AUBL002011';
export enum errorTypeOption {
  INFO = 'info',
  SUCCESS = 'success',
  ERROR = 'error',
  LOADING = 'loading',
  VERIFIED = 'verified',
  BLANK = '',
}
export const nameListForAccDropdown = ['NickName', 'CASAAccountName'];

export enum ENTITLEMENT {
  EMPLOYEE = 'employee',
  MERCHANT = 'merchant',
}
export const allowedFileSize = 2;
export const FCM_TOKEN_KEY = 'fcmToken';
export const BROWSER_ID = 'browser_id';
// Intentional, previously getting in API response, after API change, using static
export const CrausalImageData = {
  width: 553,
  height: 381,
};

export const BbpsConstants = {
  BbpsKey: 'bbps_key',
  BBPS_CONFIG: 'BBPS_CONFIG',
};
export const incomeTurnoverEndpoints = {
  income: 'annualIncomeDetails',
  turnover: 'annualTurnoverDetailsV1',
};

export enum CONSTITUTION_TYPE {
  IND = 'IND',
  SOLPROP = 'SOLPROP',
}
export const CHANNEL_REF_NO = { MM: 'MM' };
export const POSE_VINE_RETURN_URL =
  'https://iposmartuat.aubankuat.in/ipo-onnet-aub/api/login';
