import { useTranslation } from 'react-i18next';

import { AU_Badge, Icon, XB_Button } from '@core-components/atoms';
import { RegEx } from '@core-constants';
import { getINRFormatWithDecimal } from '@core-utils';

interface CardAccountDetailsProps {
  accountStatus: string;
  nickName: string;
  accountBalance: string;
  accountNumber: string;
  accountHolderName: string;
  isVisible: boolean;
  onPressAccountEye: () => void;
  onPressViewBtn: () => void;
  isSCFAccount?: boolean;
}

export const AU_CardAccountDetails = ({
  accountStatus,
  nickName,
  accountBalance,
  accountNumber,
  accountHolderName,
  isVisible = true,
  onPressAccountEye,
  onPressViewBtn,
  isSCFAccount = false,
}: CardAccountDetailsProps) => {
  const { t } = useTranslation();
  const mask = (num: string) => {
    return num.replace(RegEx.masking, 'X');
  };

  const getIconName = () => {
    let str = '';
    if (accountStatus === 'Active') {
      str = 'greendot';
    } else if (accountStatus === 'Blocked') {
      str = 'alertredtriangle';
    } else {
      str = 'alerttriangle';
    }
    return str;
  };
  const handleBadgeColor = (status: string) => {
    switch (status) {
      case 'active':
        return 'bg-green-50';
      case 'dormant':
        return 'bg-orange-50';
      case 'blocked':
        return 'bg-red-50';
      case 'inactive':
        return 'bg-orange-50';
      case 'unclaimed':
        return 'bg-orange-50';
    }
  };
  const handleBadgeTextColor = (status: string) => {
    switch (status) {
      case 'active':
        return 'text-green-700';
      case 'dormant':
        return 'text-orange-700';
      case 'blocked':
        return 'text-red-700';
      case 'inactive':
        return 'text-orange-700';
      case 'unclaimed':
        return 'text-orange-700';
    }
  };

  return (
    <div className="card-account-card-container p-4 rounded-xl bg-white mt-4 flex flex-col">
      <div className="card-badge-container flex justify-normal items-center gap-x-1">
        <AU_Badge
          className={handleBadgeColor(accountStatus?.toLocaleLowerCase())}
          contentClass={`m-text-md-medium ${handleBadgeTextColor(
            accountStatus.toLocaleLowerCase()
          )}`}
          iconName={getIconName()}
          iconColor="none"
        >
          {accountStatus}
        </AU_Badge>
        {isSCFAccount && (
          <AU_Badge
            className="bg-blue-50 px-1"
            contentClass="text-blue-700 m-text-md-medium"
          >
            {t('scfFacilityAccounts')}
          </AU_Badge>
        )}
        <p className="m-text-sm-medium text-gray-500 ml-2">{nickName}</p>
      </div>
      <div className="summary-card-main-container mt-4 flex flex-col md:justify-between md:flex-row gap-4">
        <div className="card-detail-box-1 w-1/3">
          <p className="whitespace-nowrap m-text-sm-medium text-gray-500">
            {t('accountHolder')}
          </p>

          <h5
            style={{ marginLeft: 0 }}
            className="acc-holder-name m-text-lg-medium text-gray-900 overflow-hidden text-ellipsis break-all"
          >
            {accountHolderName}
          </h5>
        </div>
        <div className="card-detail-box-2">
          <p className="whitespace-nowrap m-text-sm-medium text-gray-500">
            {t('accNumber')}
          </p>
          <h5 className="m-text-lg-medium text-gray-900">{accountNumber}</h5>
        </div>

        <div className="card-detail-box-3">
          <p className="m-text-sm-medium text-gray-500">
            {t('availableBalance')}
          </p>
          <div
            style={{ marginLeft: 0 }}
            className="flex available-bal items-center overflow-hidden text-ellipsis "
          >
            {isVisible ? (
              <h5 className="available-balance-text text-base font-medium text-gray-900 m-text-lg-medium">
                {getINRFormatWithDecimal(Number(accountBalance)).split('.')[0]}
                <span className="text-sm">
                  {`.${
                    getINRFormatWithDecimal(Number(accountBalance)).split(
                      '.'
                    )[1]
                  }`}
                </span>
              </h5>
            ) : (
              <h5 className="text-gray-900 m-text-lg-medium">
                {
                  mask(getINRFormatWithDecimal(Number(accountBalance))).split(
                    '.'
                  )[0]
                }
                <span className="text-sm">
                  {`.${
                    mask(getINRFormatWithDecimal(Number(accountBalance))).split(
                      '.'
                    )[1]
                  }`}
                </span>
              </h5>
            )}
            <span onClick={onPressAccountEye} className="cursor-pointer">
              <Icon
                icon={isVisible ? 'eyeOff' : 'eyeicon'}
                width={16}
                height={16}
                color={isVisible ? 'none' : 'text-gray-900'}
                className="ml-2 text-gray-900"
              />
            </span>
          </div>
        </div>
        <div className="card-detail-box-4 view-details-container">
          <XB_Button
            dataTestId="VIEW"
            btnType="secondary-gray"
            size="sm"
            iconName="eyeicon"
            iconPos="left"
            classes="view-details-btn"
            onClick={onPressViewBtn}
          >
            {t('view')}
          </XB_Button>
        </div>
      </div>
    </div>
  );
};
