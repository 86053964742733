import { Error } from '@core-stores';
import {
  IBillerDetails,
  IBillerNickname,
  IBillPaymentsSlice,
} from '@modules/bill-payments/models/bill-payments.model';
import { billerDetails } from '@modules/bill-payments/stores//thunks/bill-payments.thunk';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

const initialState: IBillPaymentsSlice = {
  billerSearchInput: '',
  billerNickname: {} as IBillerNickname,
  billerDetails: {} as IBillerDetails,
  error: {} as Error,
  loading: false,
};

export const billPaymentsSlice = createSlice({
  name: 'BillPayments',
  initialState,
  reducers: {
    setBillerSearchInput: (state, action: PayloadAction<string>) => {
      state.billerSearchInput = action?.payload;
    },
    setBillerNickname: (state, action: PayloadAction<IBillerNickname>) => {
      state.billerNickname = action?.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(billerDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      billerDetails.fulfilled,
      (state, { payload }: { payload: IBillerDetails }) => {
        state.billerDetails = payload;
        state.loading = false;
      }
    );
    builder.addCase(
      billerDetails.rejected,
      (state, { payload }: { payload: any }) => {
        state.error = payload;
        state.billerDetails = {} as IBillerDetails;
        state.loading = false;
      }
    );
  },
});

export const { setBillerSearchInput, setBillerNickname } =
  billPaymentsSlice.actions;

export default billPaymentsSlice.reducer;
