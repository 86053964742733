export interface ILanguageType {
  languageCode: string;
  languageName: string;
  languageVersion: string;
  translations: object;
}

export interface IAvailableLanguageType {
  code: string;
  name: string;
  language?: { name: string; code: string; latestVersion: string };
}

export interface DebitCardListType {
  id: number;
  name: string;
  code: string;
  imageUrlFront: string;
  imageUrlBack: string;
}

export interface ScheduleMaintenace {
  startTime: string;
  endTime: string;
  isSchedule: boolean | undefined;
}

export interface IGlobalConfigActiveAccountsObj {
  accountStatements: string[];
  casaSummary: string[];
  csrNominee: string[];
  csrRequestCheque: string[];
  dashboardCASA: string[];
  dashboardDeposits: string[];
  dashboardLoans: string[];
  depositClosing: string[];
  depositOpening: string[];
  depositSummary: string[];
  loanPayOverdue: string[];
  loanStatements: string[];
  loanSummary: string[];
  payments: string[];
}

export interface AppInitState {
  globalConfig: {
    activeAccounts: string;
    otpRequestBackOff: string;
    aadhaarOtpLength: string;
    defaultRetryBackOff: string;
    otpExpiry: string;
    requestOtpAttempts: string;
    loginAttempts: string;
    blank: string;
    smbPollInterval: string;
    language: string;
    mobileOtpLength: string;
    latestAppVersion: string;
    incorrectOtpAttempts: string;
    personaCode: string;
    exiomSmsMobileNumbers: string;
    languageList: string;
    personaVersion: string;
    incorrectOtpAttemptss: string;
    localhost: string;
    appStoreUrl: string;
    smbMaxAttempt: string;
    biometricLoginAttempts: string;
    isSimBinding: string;
    otp: string;
    defaultRetryCount: string;
    isEncryptionEnabled: string;
    latestVersion: string;
    mpinLoginAttempts: string;
    isForceUpdate: string;
    webUserInactivityTime: string;
    webUserAutoRefreshThreshold: string;
    webOtpLength: string;
    webOtpSeparatorLength: string;
    pollAPIMaxTriesLimit: string;
    pollAPIHitInterval: string;
    allowDuplicateFileInOnboarding: DuplicateFileMap;
    mobile_update?: boolean;
    isNTBAllowQR: boolean;
    isETBAllowQR: boolean;
    gst_ifsc: string;
    direct_tax_ifsc: string;
    direct_tax_to_account_holder_name: string;
    scheduledMaintenance?: ScheduleMaintenace;
    settlementAccountAllowedForNonAu?: boolean;
    nomineeDisallowCustomerType?: string;
    isCKYCRequired?: boolean;
  };
  urlConfig:
    | {
        localhost: string;
        appStoreUrl: string;
      }
    | undefined;
  isEncryptionEnabled: boolean;
  appUpdate:
    | {
        latestVersion: string;
        isForceUpdate: boolean;
      }
    | undefined;
  registration:
    | {
        registrationStatus: string;
        registeredMobileNumber: string;
        isCifPresent: boolean;
        cifNumber: boolean;
        panNumber: string | null;
        onBoardingStatus: string;
      }
    | undefined;
  language: ILanguageType | undefined;
  appType: string;
  debitCardList: DebitCardListType[];
  available_languages: IAvailableLanguageType[] | undefined;
  theme: ThemeConfig | undefined;
  browserNavigation: {
    global: boolean;
    page: boolean;
  };
}

export interface ThemeConfig {
  themeType?: string;
  navThemeType?: string;
  config?: ThemeRes;
}

export interface ThemeRes {
  bankDark: object | undefined;
  bankLight: object | undefined;
  storeDark: object | undefined;
  storeLight: object | undefined;
}

export enum DuplicateFileMap {
  NO_DUPLICATE = "Don't Allow Duplicate",
  OVERRIDE_DUPLICATE = 'Override Duplicate',
  ALLOW_DUPLICATE = 'Allow Duplicate',
}
