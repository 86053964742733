import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  AU_RoundBox,
  Icon,
  XB_Button,
  XB_Input,
  XB_Modal,
} from '@core-components/atoms';
import { XB_AuthStep } from '@core-components/organisms';
import {
  appConstants,
  CREDENTIAL_TYPE,
  RegEx,
  VERIFICATION_MODE,
} from '@core-constants';
import { useDebounce } from '@core-hooks';
import {
  resetUserDetails,
  setUserDetails,
  userRegistration,
} from '@core-services';
import {
  getSha256HashedValue,
  getStoredLanguage,
  isEmpty,
  maskEmailId,
  validateConfirmPassword,
  validatePassword,
} from '@core-utils';
import { Account } from '@modules/mr-registration/models/account.model';
import { CIFRecord } from '@modules/mr-registration/models/cif-record.model';
import { customerTypes } from '@src/modules/mr-registration/constants/cif-selection.constant';

interface PasswordProps {
  onPasswordSaved: () => void;
  username: string;
  title: string;
  mobileNumber: string;
  isUserOnboarded: boolean;
  description: string;
  resetPassword?: boolean;
  userEmail?: string;
  selectedCIF?: CIFRecord;
  solePropCif?: CIFRecord | null;
  otpId?: string;
  verificationMode?: string;
  ntbName?: string;
  businessName?: string;
}
interface SetIdentityRequest {
  verificationId?: string;
  appId: string;
  credentialValue: string;
  username?: string;
}

interface ICIFDetails {
  cifNumber: string;
  cifType: string;
  accountList: [
    {
      accountNumber: string;
      accountHolderName: string;
      accountType: string;
      productCode: string;
      productName: string;
    }
  ];
  default: boolean;
}

interface OnboardRequest {
  mobileNumber: string;
  username: string;
  password: string;
  cifNumber?: string;
  email?: string;
  cifType?: string;
  accountList?: Account[];
  name?: string;
  preferredLanguage?: string;
  constitutionCode: string;
  businessName: string;
  cif: {
    entityCif: ICIFDetails | null;
    userCif: ICIFDetails | null;
  };
}

export const XB_SetPassword = ({
  onPasswordSaved,
  title,
  description,
  username,
  mobileNumber,
  isUserOnboarded,
  resetPassword,
  userEmail,
  selectedCIF,
  solePropCif,
  otpId,
  verificationMode = VERIFICATION_MODE.OTP_MOBILE,
  ntbName,
  businessName,
}: PasswordProps) => {
  const { t } = useTranslation();
  const [confirmPasswordType, setConfirmPasswordType] = useState(false);
  const [enterPassword, setEnterPassword] = useState('');
  const [enterPasswordBlur, setEnterPasswordBlur] = useState<boolean>(false);
  const [confirmPassword, setconfirmPassword] = useState('');
  const [confirmPwdErr, setConfirmPwdErr] = useState('');
  const [enterPwdErr, setEnterPwdErr] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [errortitle, setErrorTitle] = useState('');
  const [errorSubtitle, setErrorSubTitle] = useState('');
  const enterPwddebounce = useDebounce(
    enterPassword,
    appConstants.DEBOUNCE_TIME
  );
  const confirmPwddebounce = useDebounce(
    confirmPassword,
    appConstants.DEBOUNCE_TIME
  );
  const [passwordType, setPasswordType] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [confirmPwdDisabled, setConfirmPwdDisabled] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalSubTitle, setModalSubTitle] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const selectedLanguage = getStoredLanguage();
  const navigate = useNavigate();
  const setErrorModal = (mTitle, mSubTitle) => {
    setIsDisabled(false);
    setIsModalVisible(true);
    setModalTitle(mTitle);
    setModalSubTitle(mSubTitle);
  };

  const setUserData = async () => {
    let response;
    const data: SetIdentityRequest = {
      verificationId: otpId,
      appId: 'com.au.rter',
      credentialValue: getSha256HashedValue(confirmPwddebounce) ?? '',
      username,
    };
    const headers = {
      CredentialType: CREDENTIAL_TYPE.PASSWORD,
      VerificationMode: verificationMode,
    };
    try {
      response = await setUserDetails(data, headers);
    } catch (err: any) {
      setErrorModal(t('technicaIssue'), err.displayMessage);
    }
    return response;
  };

  const resetUserData = async () => {
    let response;
    const data: SetIdentityRequest = {
      verificationId: otpId,
      appId: 'com.au.rter',
      credentialValue: getSha256HashedValue(confirmPwddebounce) ?? '',
    };
    const headers = {
      CredentialType: CREDENTIAL_TYPE.PASSWORD,
      VerificationMode: verificationMode,
    };

    try {
      response = await resetUserDetails(data, headers);
    } catch (err: any) {
      if (
        err?.bucketMessage?.includes('UMS0420') ||
        err?.bucketMessage?.includes('UMS0466')
      ) {
        setErrorModal(t('samePassword'), err?.displayMessage);
      } else {
        setErrorModal(t('technicaIssue'), err.displayMessage);
      }
    }
    return response;
  };

  const getEntityCIf = (): ICIFDetails => {
    return {
      cifNumber: solePropCif?.customerId ?? '',
      cifType: solePropCif?.constitutionTypeCode ?? '',
      accountList: [
        {
          accountNumber: solePropCif?.accounts?.[0]?.accountNumber ?? '',
          accountHolderName:
            solePropCif?.accounts?.[0]?.accountHolderName ?? '',
          accountType: solePropCif?.accounts?.[0]?.accountType ?? '',
          productCode: solePropCif?.accounts?.[0]?.productCode ?? '',
          productName: solePropCif?.accounts?.[0]?.productName ?? '',
        },
      ],
      default: true,
    };
  };

  const isSolePropRegistration = () => {
    return (
      solePropCif?.customerId === selectedCIF?.customerId &&
      customerTypes.SolePropTypes?.includes(
        selectedCIF?.constitutionTypeCode ?? ''
      )
    );
  };

  const isNTB = () => {
    return !selectedCIF?.customerId && !isSolePropRegistration();
  };

  const getUserCif = (): ICIFDetails | null => {
    // In single sole prop registration or NTB Registration
    if (isSolePropRegistration() || isEmpty(selectedCIF?.customerId))
      return null;
    else
      return {
        cifNumber: selectedCIF?.customerId ?? '',
        cifType: 'IND',
        accountList: [
          {
            accountNumber: selectedCIF?.accounts?.[0]?.accountNumber ?? '',
            accountHolderName:
              selectedCIF?.accounts?.[0]?.accountHolderName ?? '',
            accountType: selectedCIF?.accounts?.[0]?.accountType ?? '',
            productCode: selectedCIF?.accounts?.[0]?.productCode ?? '',
            productName: selectedCIF?.accounts?.[0]?.productName ?? '',
          },
        ],
        default: isEmpty(solePropCif),
      };
  };

  const onboardUserData = async () => {
    let response;
    const header = {
      userRelationType:
        selectedCIF?.customerId && !isSolePropRegistration()
          ? appConstants.CUSTOMER_TYPE.ETB
          : appConstants.CUSTOMER_TYPE.NTB,
    };

    let entityCif: ICIFDetails | null = null;
    if (!isEmpty(solePropCif)) {
      entityCif = getEntityCIf();
    }

    let userCif: ICIFDetails | null = null;
    userCif = getUserCif();

    const data: OnboardRequest = {
      mobileNumber:
        mobileNumber?.length > 10 ? mobileNumber?.substring(4) : mobileNumber,
      username,
      password: getSha256HashedValue(confirmPwddebounce) ?? '',
      preferredLanguage: selectedLanguage?.toUpperCase(),
      email: isNTB() ? userEmail : '',
      name: isNTB() ? ntbName : '',
      businessName: isEmpty(businessName) ? '' : businessName ?? '',
      constitutionCode: !isEmpty(solePropCif)
        ? solePropCif?.customerType ?? ''
        : selectedCIF?.customerType ?? '',
      cif: {
        entityCif,
        userCif,
      },
    };
    if (selectedCIF?.customerId) {
      data.cifNumber = selectedCIF?.customerId;
      data.email = userEmail;
      data.accountList = selectedCIF?.accounts ?? [];
      data.name = selectedCIF?.fullName;
    }
    try {
      response = await userRegistration(data, header);
    } catch (err: any) {
      if (err?.bucketMessage?.includes('UMS0454')) {
        setErrorTitle(t('emailAlreadyExists'));
        setErrorSubTitle(
          t(err?.displayMessage, { emailID: maskEmailId(userEmail ?? '') })
        );
        setShowModal(true);
      } else {
        setErrorTitle(err?.getErrorMessage() ?? 'defaultError');
        setShowModal(true);
      }
    }
    return response;
  };

  const savePasswordAndUser = async () => {
    setIsDisabled(true);
    setShowLoader(true);
    try {
      let response;
      if (isUserOnboarded) {
        response = await setUserData();
      } else if (resetPassword) {
        response = await resetUserData();
      } else {
        response = await onboardUserData();
      }
      if (response?.status === 'success') {
        onPasswordSaved();
      }
    } catch (err: any) {
      setErrorModal(t('emailAlreadyRegistered'), err.displayMessage);
    } finally {
      setIsDisabled(false);
      setShowLoader(false);
    }
  };

  const onIconClick = (event) => {
    if (!confirmPwdDisabled) {
      if (event.type === 'pointerdown') {
        setConfirmPasswordType((prev) => !prev);
      }
    }
  };

  const onPasswordIconClick = (event) => {
    if (event.type === 'pointerdown') {
      setPasswordType((prev) => !prev);
    }
  };

  useEffect(() => {
    const { error, errorOption } = validatePassword(
      enterPwddebounce,
      8,
      32,
      username,
      confirmPassword
    );
    if (enterPwddebounce.length > 0 && error) {
      setEnterPwdErr(t(error, errorOption));
      setConfirmPwdDisabled(true);
    } else if (enterPassword.length === 0) {
      setConfirmPwdDisabled(true);
      setEnterPwdErr('');
    } else {
      setConfirmPwdDisabled(false);
      setEnterPwdErr('');
    }
  }, [enterPwddebounce, t, enterPassword, confirmPassword, username]);

  useEffect(() => {
    const { error, errorOption } = validateConfirmPassword(
      enterPassword,
      confirmPassword
    );
    if (confirmPwddebounce.length > 0 && error && enterPassword.length > 0) {
      setConfirmPwdErr(t(error, errorOption));
    } else if (enterPassword.length < 1) {
      setconfirmPassword('');
      setConfirmPwdErr('');
    }
    // else if (!isButtonDisabledFunc()) {
    //   setConfirmPwdErr('passwordMatched');
    // }
    else {
      setConfirmPwdErr('');
    }
  }, [t, confirmPwddebounce, confirmPassword, enterPassword]);

  const messageArr = [
    {
      type: 1,
      desc: 'eightCharMin',
    },
    {
      type: 2,
      desc: 'atLeastOneNumber',
    },
    {
      type: 3,
      desc: 'atleastOneSymbol',
    },
    {
      type: 4,
      desc: 'oneUppercaseLetter',
    },
  ];
  const renderIcon = (type) => {
    if (enterPasswordBlur && enterPassword.length > 0) {
      return (type === 1 && enterPassword.length >= 8) ||
        (type === 2 && RegEx.atLeastOneNumber.test(enterPassword)) ||
        (type === 3 && RegEx.atleastOneSpecialCharacter.test(enterPassword)) ||
        (type === 4 && RegEx.atleastOneUpperCaseLetter.test(enterPassword))
        ? 'greenCircle'
        : 'failedIcon';
    }
    return (type === 1 && enterPassword.length >= 8) ||
      (type === 2 && RegEx.atLeastOneNumber.test(enterPassword)) ||
      (type === 3 && RegEx.atleastOneSpecialCharacter.test(enterPassword)) ||
      (type === 4 && RegEx.atleastOneUpperCaseLetter.test(enterPassword))
      ? 'greenCircle'
      : 'info-outlined-outlined';
  };

  const isButtonDisabledFunc = () => {
    return (
      !(
        enterPassword.length > 0 &&
        confirmPassword.length > 0 &&
        enterPwdErr === '' &&
        confirmPwdErr === '' &&
        enterPassword === confirmPassword
      ) ||
      isDisabled ||
      showLoader
    );
  };

  const getReenterMsgType = () => {
    if (!isButtonDisabledFunc()) {
      return 'verified';
    } else if (confirmPwdErr) {
      return 'error';
    } else {
      return '';
    }
  };

  return (
    <XB_AuthStep
      title={title}
      description={description}
      mainContent={
        <>
          <XB_Modal
            headPos="text-left"
            maxWidth="25rem"
            open={showModal}
            setOpen={() => setShowModal(true)}
            img={
              <AU_RoundBox boxClass="text-warning-100">
                <Icon
                  width={48}
                  height={48}
                  color={'transparent'}
                  icon="alerttriangle"
                  className="text-orange-600"
                />
              </AU_RoundBox>
            }
            title={errortitle}
            subTitle={errorSubtitle}
            actions={
              <div className="flex flex-col">
                <XB_Button
                  dataTestId="PRCD"
                  classes="mb-2"
                  btnType="primary"
                  onClick={() => navigate('/auth/login')}
                >
                  {t('login')}
                </XB_Button>
                <XB_Button
                  dataTestId="CNCL"
                  onClick={() => setShowModal(false)}
                  btnType="secondary-gray"
                >
                  {t('cancel')}
                </XB_Button>
              </div>
            }
          />
          <XB_Input
            id="enter-password"
            type={passwordType ? 'text' : 'password'}
            label={`${t('password')}`}
            placeholder={`${t('enterPassword')}`}
            suffixIcon={passwordType ? 'eyeOff' : 'eyeicon'}
            onIconClick={onPasswordIconClick}
            onInputChange={(val) => {
              setEnterPassword(val);
              setEnterPasswordBlur(false);
            }}
            value={enterPassword.split(' ').join('')}
            msgType={enterPwdErr ? 'error' : ''}
            infoMsgText={enterPwdErr}
            maxLength={32}
            onBlur={() => {
              setEnterPasswordBlur(true);
            }}
          />
          <div className="flex flex-col gap-2 mt-2">
            {messageArr.map((item, index) => {
              return (
                <div className="flex items-center gap-x-2 mt-1" key={index}>
                  {
                    <Icon
                      icon={renderIcon(item.type)}
                      width={16}
                      height={16}
                      color="none"
                    />
                  }
                  <p className="text-color block m-text-md-regular">
                    {t(item.desc)}
                  </p>
                </div>
              );
            })}
          </div>
          <>
            <XB_Input
              id="confirm-password"
              classes="mt-6"
              placeholder={`${t('confirmPassword')}`}
              type={confirmPasswordType ? 'text' : 'password'}
              label={`${t('confirmPassword')}`}
              suffixIcon={confirmPasswordType ? 'eyeOff' : 'eyeicon'}
              onInputChange={setconfirmPassword}
              onIconClick={onIconClick}
              value={confirmPassword.split(' ').join('')}
              msgType={getReenterMsgType()}
              infoMsgText={
                !isButtonDisabledFunc() ? t('passwordMatched') : confirmPwdErr
              }
              maxLength={32}
              disabled={confirmPwdDisabled}
            />
          </>
          <XB_Modal
            title={modalTitle}
            subTitle={modalSubTitle}
            maxWidth="25rem"
            img={
              <Icon
                width={48}
                height={48}
                color={'transparent'}
                icon="alert-modal"
              />
            }
            actions={
              <>
                <XB_Button
                  dataTestId="clear"
                  classes="float-right"
                  btnType="primary"
                  onClick={() => {
                    setIsModalVisible(false);
                    setShowLoader(false);
                  }}
                >
                  {t('ok')}
                </XB_Button>
              </>
            }
            open={isModalVisible}
            setOpen={setIsModalVisible}
          />
        </>
      }
      footerContent={
        <XB_Button
          classes="w-full"
          dataTestId="PRCD"
          disabled={isButtonDisabledFunc()}
          showLoader={showLoader}
          onClick={() => {
            savePasswordAndUser();
          }}
        >
          {t('proceed')}
        </XB_Button>
      }
    />
  );
};
