import { QuickLinksDataProps } from '@core-components/molecules';

import { ModalDescriptionType } from '../index';

export const notToShowCardStatus = ['C', 'D'];

export const blockCardStatus = ['B', 'D'];

export const cardStatusObj = { A: 'A', I: 'I' };

export const statusActionObj = { B: 'B', D: 'D', Blank: null };

export const debitConfigKey = 'FE_DEBIT_CARD_CONFIG';

export const sliderSpeed = 1000;

export const quickLinksData: QuickLinksDataProps[] = [
  {
    prefixIcon: 'card-icon',
    title: 'offerManagementLbl',
    description: 'impsRtgs',
    suffixBtnIcon: true,
    prefixIconProps: {
      className: 'text-primary-600',
    },
  },
  {
    prefixIcon: 'debitcard',
    title: 'creditCards',
    description: 'viewAndDownload',
    suffixBtnIcon: true,
    prefixIconProps: {
      className: 'text-primary-600',
    },
  },
];

export const ONE = 1;

export const tmpCardUsageSecitonList = [
  {
    icon: 'atm-use-icon',
    title: 'atmUse',
    infoDescription: 'atmUsageDescription',
    isToggleActive: false,
    amountVal: '0',
    minAmountVal: '1',
    maxAmountVal: '100000',
    minValToShow: `₹1`,
    maxValToShow: `₹1,00,000`,
  },
  {
    icon: 'pos-usage-icon',
    title: 'posUse',
    infoDescription: 'posUsageDescription',
    isToggleActive: false,
    amountVal: '0',
    minAmountVal: '1',
    maxAmountVal: '100000',
    minValToShow: `₹0`,
    maxValToShow: `₹1,00,000`,
  },
  {
    icon: 'online-usage-icon',
    title: 'onlineUse',
    infoDescription: 'onlineUsageDescription',
    isToggleActive: false,
    amountVal: '0',
    minAmountVal: '1',
    maxAmountVal: '100000',
    minValToShow: `₹1`,
    maxValToShow: `₹1,00,000`,
  },
  {
    icon: 'cardless-icon',
    title: 'contactlessUse',
    infoDescription: 'contactlessUseDescription',
    isToggleActive: false,
    amountVal: '0',
    minAmountVal: '1',
    maxAmountVal: '100000',
    minValToShow: `₹1`,
    maxValToShow: `₹1,00,000`,
  },
];

export const cardUsageSecitonList = [
  {
    icon: 'atm-use-icon',
    title: 'atmUse',
    infoDescription: 'atmUsageDescription',
    isToggleActive: false,
    amountVal: '0',
    minAmountVal: '1',
    maxAmountVal: '100000',
    minValToShow: `₹1`,
    maxValToShow: `₹1,00,000`,
  },
  {
    icon: 'pos-usage-icon',
    title: 'posUse',
    infoDescription: 'posUsageDescription',
    isToggleActive: false,
    amountVal: '0',
    minAmountVal: '1',
    maxAmountVal: '100000',
    minValToShow: `₹0`,
    maxValToShow: `₹1,00,000`,
  },
  {
    icon: 'online-usage-icon',
    title: 'onlineUse',
    infoDescription: 'onlineUsageDescription',
    isToggleActive: false,
    amountVal: '0',
    minAmountVal: '1',
    maxAmountVal: '100000',
    minValToShow: `₹1`,
    maxValToShow: `₹1,00,000`,
  },
  {
    icon: 'cardless-icon',
    title: 'contactlessUse',
    infoDescription: 'contactlessUseDescription',
    isToggleActive: false,
    amountVal: '0',
    minAmountVal: '1',
    maxAmountVal: '100000',
    minValToShow: `₹1`,
    maxValToShow: `₹1,00,000`,
  },
];
export const intCardUsageSecitonList = [
  {
    icon: 'atm-use-icon',
    title: 'atmUse',
    infoDescription: 'atmUsageDescription',
    isToggleActive: false,
    amountVal: '40200',
    minAmountVal: '1',
    maxAmountVal: '100000',
    minValToShow: `₹1`,
    maxValToShow: `₹1,00,000`,
  },
  {
    icon: 'pos-usage-icon',
    title: 'posUse',
    infoDescription: 'posUsageDescription',
    isToggleActive: false,
    amountVal: '40000',
    minAmountVal: '1',
    maxAmountVal: '100000',
    minValToShow: `₹0`,
    maxValToShow: `₹1,00,000`,
  },
  {
    icon: 'online-usage-icon',
    title: 'onlineUse',
    infoDescription: 'onlineUsageDescription',
    isToggleActive: false,
    amountVal: '40000',
    minAmountVal: '1',
    maxAmountVal: '100000',
    minValToShow: `₹1`,
    maxValToShow: `₹1,00,000`,
  },
  {
    icon: 'cardless-icon',
    title: 'contactlessUse',
    infoDescription: 'contactlessUseDescription',
    isToggleActive: false,
    amountVal: '40000',
    minAmountVal: '1',
    maxAmountVal: '100000',
    minValToShow: `₹1`,
    maxValToShow: `₹1,00,000`,
  },
];

interface SecondaryBtnProps {
  label?: string;
  onCLickSecondaryBtn: () => void;
}
export interface ErrorMsgConstant {
  showError: boolean;
  title: string;
  description: string;
  secondaryBtnProps?: SecondaryBtnProps;
}

export interface SuccessMsgConstant {
  showSuccess: boolean;
  title: string;
  description: string;
}

export const defaultErrorMsg = {
  showError: false,
  title: '',
  description: '',
};
export const REPLACEMENT_LIMIT = 0;
export const BLOCK_TYPE_TEMP = 'TMB';
export const BLOCK_TYPE_PERM = 'LODLOST';
export const otpTypes = {
  getcvv: 'GET_CVV',
  unblock: 'UNBLOCK',
  getCvvOnClick: 'GET_CVV_CLICK',
};
export const BLANK = '';
export const booleanValues = { true: true, false: false };

export const rewardList = [
  { title: 'earnedPoint', value: 0 },
  { title: 'redeemedPoint', value: 0 },
  { title: 'laspedPoint', value: 0 },
  { title: 'redeemablePoint', value: 0 },
];
export const modalDescriptionTemp: ModalDescriptionType = {
  title: 'msgDebitCardBlockedTemp',
  useCases: [
    {
      descriptionText: 'msgUnblockDebitCard',
      icon: 'outline-unblocked-card',
    },
  ],
  hasInfo: false,
  info: '',
  refrenceNo: '1234567890',
};
export const modalDescriptionReIssue: ModalDescriptionType = {
  title: 'msgDebitCardBlockedPermanent',
  useCases: [
    {
      descriptionText: 'msgUnblockDebitCard',
      icon: 'outline-unblocked-card',
    },
    {
      descriptionText: 'msgReIssueDebitCard',
      icon: 'outline-wallet',
    },
    {
      descriptionText: 'msgReIssueDebitCardSub',
      icon: 'outline-unblocked-card',
    },
  ],
  hasInfo: true,
  info: 'msgReIssueDebitCardInfo',
  refrenceNo: '1234567890',
};

export const modalDescriptionPerm: ModalDescriptionType = {
  title: 'msgDebitCardBlockedPermanent',
  useCases: [],
  hasInfo: false,
  info: '',
  refrenceNo: '1234567890',
};

export const URLViewCharges = 'https://www.aubank.in/service-fee';
export const TypeSkipReIssue = 'skip';
export const TypeConfirmReIssue = 'reIssue';

export const apiFunctions = { GETLIMIT: 'GETLIMIT', SETLIMIT: 'SETLIMIT' };

export const cardExpiryDateFromat = 'MM/YY';

export const encrypteCardExpiryDateFromat = 'XX/XX';

export const encrypteCVV = 'XXX';

export const numberOfSlideToShow = 1;

export const responseModalTypeConst = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
};

export const responseModalData = {
  cardusage: {
    success: {
      heading: 'changesSaved',
      subTitle: 'cardUsageUpdated',
      btnText: 'backToDebitCard',
      btnID: 'USAG_BACK',
      icon: 'outline-circle-check',
    },
    error: {
      heading: 'cardNotUpdated',
      subTitle: 'couldNotSavedChanges',
      btnText: 'tryAgain',
      btnID: 'USAG_AGIN',
      icon: 'outline-warning',
    },
    warning: {
      heading: 'someChangesNotUpdated',
      subTitle: '',
      btnText: 'backToDebitCard',
      btnID: 'USAG_BACK',
      icon: 'warningmodal',
      inlineMessages: [
        {
          title: 'cardUsageUpdate',
          subTitle: 'yourCardUpdateSuccessfully',
          type: 'success',
          icon: 'info-circle',
        },
        {
          title: 'limitNotUpdate',
          subTitle: 'someTechnicalErrChangeLimit',
          type: 'error',
          icon: 'tick-circle',
        },
      ],
    },
  },
  setpin: {
    success: {
      heading: 'pinGenerated',
      subTitle: 'pinGeneratedSubMessage',
      btnText: 'backToDebitCard',
      btnID: 'USAG_BACK',
      icon: 'outline-circle-check',
    },
    error: {
      heading: 'somethingWentWrong',
      subTitle: '',
      btnTextOne: 'goToHome',
      btnText: 'tryAgain',
      btnID: 'USAG_AGIN',
      icon: 'outline-warning',
    },
  },
  blockcard: {
    success: {
      heading: 'msgDebitCardBlockedPermanent',
      subTitle: '',
      btnText: 'backToDebitCard',
      btnID: 'USAG_BACK',
      icon: 'outline-circle-check',
    },
    error: {
      heading: 'somethingWentWrong',
      subTitle: 'errorBlockDebitCard',
      btnTextOne: 'goToHome',
      btnText: 'tryAgain',
      btnID: 'USAG_AGIN',
      icon: 'outline-warning',
    },
  },
  debitcard: {
    success: {
      heading: 'debitCardUnblocked',
      subTitle: '',
      btnText: 'backToDebitCard',
      btnID: 'USAG_BACK',
      icon: 'outline-circle-check',
    },
  },
  rewardpoint: {
    error: {
      heading: 'somethingWentWrong',
      subTitle: 'errorDebitCardRewardsFetch',
      btnText: 'backToDebitCard',
      btnID: 'RWRD_BACK',
      icon: 'outline-warning',
    },
  },
  ssoredirection: {
    error: {
      heading: 'somethingWentWrong',
      subTitle: '',
      btnText: 'backToDebitCard',
      btnID: 'RWRD_BACK',
      icon: 'outline-warning',
    },
  },
};

export const domesticActiveFlag = ['D', 'B'];
export const internationalActiveFlag = ['I', 'B'];
export const inputLimits = { expiryLimit: 5 };
export const pinLimit = 4;
export interface TypeIntialData {
  domestic: any[];
  initernational: any[];
}
export const STATIC_ID = 'au0101business';
export const accountToGet = ['CurrentAccount', 'SavingsAccount'];
export const statusToGet = ['active'];

export const mask = (num: string) => {
  return num?.replace(/(\d{4})\d+(\d{4})/, '$1 XXXX XXXX $2');
};

export const ALL = ['ALL'];
export const OTHER = 'other';
export const STATUS_ACTIVE = 'ACTIVE';
export const INTERNATIONAL_STATUS = {
  INTERNATIONAL: 'I',
  DOMESTIC: 'D',
};

export const checkAllOfferLink = 'https://rewardz.aubank.in/';
