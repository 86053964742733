import { IBillManageSlice } from '@modules/bill-payments/models/bill-manage.model';
import { getBBPSBillsList } from '@modules/bill-payments/stores/thunks/bill-manage.thunk';
import { createSlice } from '@reduxjs/toolkit';

const initialState: IBillManageSlice = {
  status: undefined,
  data: [],
  loading: false,
  successfulResponse: undefined,
  error: undefined,
};

export const bbpsbillManageSlice = createSlice({
  name: 'BBPSBillsManage',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBBPSBillsList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getBBPSBillsList.fulfilled, (state, { payload }) => {
      ({
        loading: state.loading,
        status: state.status,
        error: state.error,
        successfulResponse: state.successfulResponse,
        data: state.data,
      } = payload);
    });
    builder.addCase(
      getBBPSBillsList.rejected,
      (state, { payload }: { payload: any }) => {
        state.status = undefined;
        state.error = payload;
        state.successfulResponse = undefined;
        state.data = [];
        state.loading = false;
      }
    );
  },
});

export default bbpsbillManageSlice.reducer;
