import { createSlice } from '@reduxjs/toolkit';

import { ResBbpsIcon } from '../../models/bill-payments.model';
import { getBbpsIcons } from '../thunks/bill-payments.thunk';

const initialState: ResBbpsIcon = {
  status: undefined,
  loading: false,
  successfulResponse: undefined,
  error: undefined,
  data: undefined,
};

const getBbpsIconsSlice = createSlice({
  name: 'getBbpsIcons',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBbpsIcons.fulfilled, (state, { payload }) => {
      state.data = payload.data;
      state.error = payload.error;
      state.status = payload.status;
      state.loading = false;
    });
    builder.addCase(getBbpsIcons.pending, (state) => {
      state.data = undefined;
      state.error = undefined;
      state.status = undefined;
      state.loading = true;
    });
    builder.addCase(
      getBbpsIcons.rejected,
      (state, { payload }: { payload: any }) => {
        state.data = undefined;
        state.error = payload;
        state.status = undefined;
        state.loading = false;
      }
    );
  },
});

export default getBbpsIconsSlice.reducer;
