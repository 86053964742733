export const notificationOptions = [
  { name: 'sms', toolTip: false, isDisable: true },
  { name: 'inApp', toolTip: false, isDisable: false },
  { name: 'email', toolTip: false, isDisable: false },
  { name: 'emailOtp', toolTip: true, isDisable: false },
];

export const debitCredit = {
  DEBIT: 'D',
  CREDIT: 'C',
};

export const timeDayFilterValues = [0, 1, 2, 3, 4, 5, 6];

export const timeDropdown = [
  { value: '00:00 am' },
  { value: '00:30 am' },
  { value: '01:00 am' },
  { value: '01:30 am' },
  { value: '02:00 am' },
  { value: '02:30 am' },
  { value: '03:00 am' },
  { value: '03:30 am' },
  { value: '04:00 am' },
  { value: '04:30 am' },
  { value: '05:00 am' },
  { value: '05:30 am' },
  { value: '06:00 am' },
  { value: '06:30 am' },
  { value: '07:00 am' },
  { value: '07:30 am' },
  { value: '08:00 am' },
  { value: '08:30 am' },
  { value: '09:00 am' },
  { value: '09:30 am' },
  { value: '10:00 am' },
  { value: '10:30 am' },
  { value: '11:00 am' },
  { value: '11:30 am' },
  { value: '12:00 pm' },
  { value: '12:30 pm' },
  { value: '01:00 pm' },
  { value: '01:30 pm' },
  { value: '02:00 pm' },
  { value: '02:30 pm' },
  { value: '03:00 pm' },
  { value: '03:30 pm' },
  { value: '04:00 pm' },
  { value: '04:30 pm' },
  { value: '05:00 pm' },
  { value: '05:30 pm' },
  { value: '06:00 pm' },
  { value: '06:30 pm' },
  { value: '07:00 pm' },
  { value: '07:30 pm' },
  { value: '08:00 pm' },
  { value: '08:30 pm' },
  { value: '09:00 pm' },
  { value: '09:30 pm' },
  { value: '10:00 pm' },
  { value: '10:30 pm' },
  { value: '11:00 pm' },
  { value: '11:30 pm' },
  { value: '11:59 pm' },
];
export const weekDays = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];

export const MERCHANT_DOCUMENT_TYPE = 'MERCHANT_PROFILE';
export const MERHCHANT_NOTIFICATION = {
  on: 'ON',
  off: 'OFF',
};

export const storeDetailTypes = {
  income: 'income',
  turnover: 'turnover',
  time: 'time',
  mobile: 'mobile',
  emailId: 'emailId',
  address: 'address',
};

export const CROSS_SELL_NAME = {
  VIDEO_BANKING: 'VB_WELCOME_PAGE',
  MOBILE_BANKING: 'VB_UPDATE_MOBILE_NO_PAGE',
  WORKFLOW_GROUP_ID: 'NTB_PRELOGIN_VB',
  help: 'VB_HELP_PAGE',
};

export const ctaLabels = {
  logout: {
    title: 'logout',
    subTitle: 'areYouSureYouWantToGenric',
    subtitleProps: {
      name: 'Vishnu',
      type: 'logout',
    },
    deviceState: 'logout',
    rightBtnLabel: 'logout',
  },
  block: {
    title: 'block',
    subTitle: 'areYouSureYouWantToGenric',
    subtitleProps: {
      name: 'Vishnu',
      type: 'block',
    },
    deviceState: 'BLOCKED',
    rightBtnLabel: 'block',
  },
  unblock: {
    title: 'unblock',
    subTitle: 'areYouSureYouWantToGenric',
    subtitleProps: {
      name: 'Vishnu',
      type: 'unblock',
    },
    deviceState: 'UNBLOCKED',
    rightBtnLabel: 'unblock',
  },
  deactivate: {
    title: 'deactivate',
    subTitle: 'areYouSureYouWantToGenric',
    subtitleProps: {
      name: 'Vishnu',
      type: 'deactivate',
    },
    rightBtnLabel: 'deactivate',
    deviceState: 'Deactivate',
  },
};
export const TwoFactorAuth = false;
export const emailOtpKey = 'email_otp_config_ntb';
export const merchantPersona = 'MR';

export const STORE_DETAIL_IMAGE = {
  MERCHANT_PROFILE: 'MERCHANT_PROFILE',
  ACCOUNT_CHEQUE: 'ACCOUNT_CHEQUE',
  SIGNATURE_CARD: 'SIGNATURE_CARD',
  Signature: 'SIGNATURE',
  SHOP_QRCODE: 'SHOP_QRCODE',
  SHOP_BOARD: 'SHOP_BOARD',
  SHOP_FULL_IMAGE: 'SHOP_FULL_IMAGE',
  Aadhaar: 'AADHAAR',
};
export const STORE_TYPE = {
  MERCHANT_MANAGEMENT: 'MERCHANT_MANAGEMENT',
};

export const reportIssueOptions = { YES: 'yes', NO: 'no', BLANK: 'blank' };

export const reportIssueMockData = {
  account: 'CA - 1235347534861234',
  debitCardsMockList: [
    { cardNo: '331234' },
    { cardNo: '129120' },
    { cardNo: '123334' },
    { cardNo: '912120' },
  ],
};

export const transactionData = [
  {
    id: 1,
    transactionType: 'D',
    transactionDate: '20, Nov 2023',
    narration:
      'UPI- Anushka Pareek - 8302876@ybl-SBIN00243852-8987834454-Payment from Phone',
    transactionAmount: '1800',
    balance: '2000',
    transactionTime: '05:30AM',
    accountNumber: '223333333333',
  },
  {
    id: 2,
    transactionType: 'D',
    transactionDate: '20, Nov 2023',
    narration:
      'UPI- Anushka Pareek - 8302876@ybl-SBIN00243852-8987834454-Payment from Phone',
    transactionAmount: '1800',
    balance: '2000',
    transactionTime: '05:30AM',
    accountNumber: '223333333333',
  },
  {
    id: 3,
    transactionDate: '20, Nov 2023',
    transactionType: 'C',
    narration:
      'UPI- Anushka Pareek - 8302876@ybl-SBIN00243852-8987834454-Payment from Phone',
    transactionAmount: '1800',
    balance: '2000',
    transactionTime: '05:30AM',
    accountNumber: '223333333333',
  },
  {
    id: 4,
    transactionType: 'D',
    transactionDate: '20, Nov 2023',
    narration:
      'UPI- Anushka Pareek - 8302876@ybl-SBIN00243852-8987834454-Payment from Phone',
    transactionAmount: '1800',
    balance: '2000',
    transactionTime: '05:30AM',
    accountNumber: '223333333333',
  },
  {
    id: 5,
    transactionType: 'D',
    transactionDate: '20, Nov 2023',
    narration:
      'UPI- Anushka Pareek - 8302876@ybl-SBIN00243852-8987834454-Payment from Phone',
    transactionAmount: '1800',
    balance: '2000',
    transactionTime: '05:30AM',
    accountNumber: '223333333333',
  },
  {
    id: 6,
    transactionType: 'D',
    transactionDate: '20, Nov 2023',
    narration:
      'UPI- Anushka Pareek - 8302876@ybl-SBIN00243852-8987834454-Payment from Phone',
    transactionAmount: '1800',
    balance: '2000',
    transactionTime: '05:30AM',
    accountNumber: '223333333333',
  },
  {
    id: 7,
    transactionType: 'C',
    transactionDate: '20, Nov 2023',
    narration:
      'UPI- Anushka Pareek - 8302876@ybl-SBIN00243852-8987834454-Payment from Phone',
    transactionAmount: '1800',
    balance: '2000',
    transactionTime: '05:30AM',
    accountNumber: '223333333333',
  },
];

export const debitCardText = 'Debit/ ATM Card xx1234';
export const accountNumber = '1234325645687689';

export const profileMpinMaxAttempts = 3;

export const ChangePasswordPaths = {
  LOGIN: '/auth/login',
  OTPC: '/profile/change-password/card-auth',
  VERIFY_SELECTION: '/profile/change-password/verify-yourself',
  DEBIT_CARDS_LISTING: '/profile/change-password/debit-cards-listing',
  AUTH_PHOTO: '/profile/change-password/photo-auth',
  NO_CAM_NO_DEBIT: '/profile/change-password/no-webcam',
  MPIN: '/profile/change-password/mpin',
};
export const CIFSelectionType = {
  CameraAndDebit: 'CameraAndDebit',
  CameraOnly: 'CameraOnly',
  NoCameraOnlyDebit: 'NoCameraOnlyDebit',
  SingleDebitCard: 'SingleDebitCard',
  MultipleDebitCards: 'MultipleDebitCards',
  NoCameraNoDebit: 'NoCameraNoDebit',
  MobileRegisteredNoDebitNoCam: 'MobileRegisteredNoDebitNoCam',
};
export const videoinput = 'videoinput';

export const FE_PROFILE_CONFIG = {
  CONFIG_TYPE: 'FE_PROFILE_CONFIG',
  MODULE: 'feProfileConfig',
};
export const FE_FRAUD_CONFIG = {
  CONFIG_TYPE: 'FE_FRAUD_CONFIG',
  MODULE: 'feFraudConfig',
};

export interface IFeFraudConfig {
  fraudConfig: {
    web: { isMobileOtp: boolean; isEmailOtp: boolean; isMpin: boolean };
    app: { isMobileOtp: boolean; isEmailOtp: boolean; isMpin: boolean };
  };
}

export const REGISTRATION_STATUS = {
  REGISTERED: 'REGISTERED',
  NOT_REGISTERED: 'NOT_REGISTERED',
};

export const TYPE_OF_CUSTOMER = {
  NTB: 'NTB',
  ETB: 'ETB',
};

export const LEAD_SOURCE = 'UBL01';

export const CRMLEAD_CONFIG = {
  CONFIG_TYPE: 'FE_CRM_LEAD_CONFIG',
  MODULE: 'crmLeadConfig',
};

export const mockedState = {
  init: {
    globalConfig: {
      otpExpiry: '30',
      requestOtpAttempts: '3',
      webOtpLength: '6',
      pollAPIMaxTriesLimit: '4v ',
      loginAttempts: '6',
      widget: '[{"language":{"code":null,"latest_version":null,"name":null}}]',
      smbPollInterval: '1000',
      blinkDetectionProbability: '0.4',
      language: '{"code":"hi","name":"हिन्दी","version":"1.0"}',
      mobileOtpLength: '6',
      latestAppVersion: '1',
      webUserInactivityTime: '120000',
      incorrectOtpAttempts: '3',
      pollAPIHitInterval: '30',
      offer: '[{"language":{"code":null,"latest_version":null,"name":null}}]',
      personaCode: 'PRSN01',
      blinkDetection: false,
      exiomSmsMobileNumbers:
        '{"1":"919990012092","2":"919899778552","3":"919829170008"}',
      'i18n-label':
        '[{"language":{"code":null,"latest_version":null,"name":null}}]',
      allowDuplicateFileInOnboarding: 'Allow Duplicate',
      strapiAuth:
        '43d77638ced50e0dd9d0177e8cbb9de05bbfeee1b377e5ac2367eb9181929d5eabaabbbf1ef07f6875b9970e44b586a9b92465a7728377b20a900868e0171e46078a9a46f26da46580dd83bb035f99d342bf292503877c22a8e77040eac1d58503b030b78bde72d18282a28728b032810125e8ec421858abe484378d2f48dd20',
      isNTBAllowQR: true,
      languageList:
        '[{"language":{"name":"English","code":"en","latestVersion":"1.3"}},{"language":{"name":"हिन्दी","code":"hi","latestVersion":"1.3"}},{"language":{"name":"Hinglish","code":"he","latestVersion":"1.0"}}]',
      personaVersion: '1.0.0',
      incorrectOtpAttemptss: '5',
      activeAccounts:
        '{"dashboardCASA": [ "6", "8"],"dashboardDeposits": [ "6", "8"],"dashboardLoans": [ "6", "8"],"depositOpening": [ "4", "6", "8", "9", "15", "16", "17"],"depositClosing": [ "3", "6", "8", "9", "14", "16", "17", "20", "21"],"depositSummary": [ "6", "8"],"casaSummary": [ "2", "3", "4", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21"],"accountStatements": [ "2", "3", "4", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21"],"loanStatements": [ "6", "8"],"payments": [ "4", "6", "8", "9", "15", "16", "17"],"loanSummary": [ "6", "8"],"loanPayOverdue": [ "4", "6", "8", "9", "15", "16", "17"],"csrNominee": [ "2", "3", "4", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21"],"csrRequestCheque": [ "2", "3", "4", "6", "8", "16", "17"] }',
      localhost: 'http://localhost:8080/',
      appStoreUrl: 'https://{au-domain.com}/{stage-version}/init-service/',
      item: '[{"language":{"code":null,"latest_version":null,"name":null}}]',
      mobile_update: true,
      smbMaxAttempt: '5',
      biometricLoginAttempts: '3',
      gst_ifsc: 'RBIS0GSTPMT',
      fileUploadSizeLimit: '2',
      otp: '500',
      defaultRetryCount: '3',
      isEncryptionEnabled: 'false',
      encryptionAllowed: [],
      fileAcceptableType: 'image/png, image/jpeg, application/pdf',
      latestVersion: '1.0.0',
      webUserAutoRefreshThreshold: '90',
      mpinLoginAttempts: '3',
      isSimbindingEnabled: 'false',
      isForceUpdate: 'false',
      webOtpSeparatorLength: '3',
    },
  },
  isEncryptionEnabled: false,
};

export const contactPermission = [
  'mobile.number.module.view',
  'email.id.module.view',
  'registered.address.module.view',
  'communication.address.module.view',
  'home.branch.module.view',
];

export const allBusinessPermissions = [
  'business.name.module.view',
  'business.cif.module.view',
  'date.of.birth.module.view',
  'company.pan.module.view',
  'aadhar.number.module.view',
  'ckyc.number.module.view',
  'mobile.number.module.view',
  'email.id.module.view',
  'registered.address.module.view',
  'communication.address.module.view',
  'home.branch.module.view',
  'date.of.incorporation.module.view',
  'company.category.module.view',
];

export const RESPONSE_MESSAGE = {
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
};

export const NOTIFICATION_READ_STATUS = {
  UNREAD: 'UNREAD',
};

export enum MFATypes {
  ALL = 'all',
  DEBIT_CARD = 'debitCard',
  FACE_MATCH = 'faceMatch',
  NONE = 'None',
}
